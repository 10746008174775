<template>
  <div>
    <van-nav-bar
      :title="navTitle"
      left-text="返回"
      left-arrow
      :fixed="true"
      :placeholder="true"
      @click-left="() => {$router.back()}"/>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad">
        <van-cell-group>
          <van-cell :clickable="true" :to="{name: 'clazzStudentStatic', params: student}" v-for="student in list" :key="student.code" :title="student.realname" :label="student.code">
            <template #right-icon>
              <span style="color: red" v-if="student.sumFee && student.sumFee.totalFee">欠费金额：{{ student.sumFee.totalFee.owedFee / 100 }}</span>
              <span v-else style="color: #969799">无欠费</span>
            </template>
          </van-cell>
        </van-cell-group>
      </van-list>
    </van-pull-refresh>
  </div>
</template>
  
<script>
const clone = require('rfdc')()
import FeeApi from '@/api/src/bill/api/FeeApi'
const feeApi = new FeeApi()

export default {
  name: 'ClazzStudentList',
  data() {
    return {
      navTitle: '',
      loading: false,
      finished: false,
      refreshing: false,
      params: {
        jwClazzIdList: [],
        jwStatus: undefined,
      },
      list: [],
      page: 0,
      total: 0,
    }
  },
  async mounted() {
    this.navTitle = this.$route.query.name
    console.log(this.$route.params.clazzId)
  },
  methods: {
    async onLoad() {
      this.loading = true;
      await this.getDate(this.page + 1)
      // this.finished = true;
      this.loading = false;
    },
    async onRefresh() {
      this.finished = false;
      this.page = 0
      await this.onLoad();
    },
    async getDate(page) {
      let resp = await feeApi.chargeTeacherStat(page, {
        jwClazzIdList: [this.$route.params.clazzId],
        jwStatus: this.params.jwStatus,
      })
      this.page = page
      this.total = resp.total
      if(page >= resp.pages){
        this.finished = true
      }
      if(this.page == 1) {
        this.list = [];
        this.refreshing = false;
        this.list = resp.list
      }else{
        let list = clone(this.list)
        list = list.concat(resp.list)
        this.list = list
      }
    }
  }
}
</script>
  
<style>

</style>