/**
 * 财务接口
 * 财务接口API
 *
 * OpenAPI spec version: 0.2.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import ApiClient from '../../ApiClient';




  /**
   * The ChargeQueryForm model module.
   * @module bill/model/ChargeQueryForm
   * @version 0.2.0
   */

  /**
   * Constructs a new <code>ChargeQueryForm</code>.
   * @alias module:bill/model/ChargeQueryForm
   * @class
   * @param jwClazzIdList {Array.<Integer>} 班级idList
   */
  var exports = function(jwClazzIdList) {
    var _this = this;

    _this['jwClazzIdList'] = jwClazzIdList;




  };

  /**
   * Constructs a <code>ChargeQueryForm</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:bill/model/ChargeQueryForm} obj Optional instance to populate.
   * @return {module:bill/model/ChargeQueryForm} The populated <code>ChargeQueryForm</code> instance.
   */
  exports.constructFromObject = function(data, obj) {
    if (data) {
      obj = obj || new exports();

      if (Object.prototype.hasOwnProperty.call(data, 'jwClazzIdList')) {
        obj['jwClazzIdList'] = ApiClient.convertToType(data['jwClazzIdList'], ['Integer']);
      }
      if (Object.prototype.hasOwnProperty.call(data, 'year')) {
        obj['year'] = ApiClient.convertToType(data['year'], 'Integer');
      }
      if (Object.prototype.hasOwnProperty.call(data, 'jwStatus')) {
        obj['jwStatus'] = ApiClient.convertToType(data['jwStatus'], 'Integer');
      }
      if (Object.prototype.hasOwnProperty.call(data, 'owedFee')) {
        obj['owedFee'] = ApiClient.convertToType(data['owedFee'], 'Boolean');
      }
      if (Object.prototype.hasOwnProperty.call(data, 'owedFeeCategory')) {
        obj['owedFeeCategory'] = ApiClient.convertToType(data['owedFeeCategory'], 'Integer');
      }
    }
    return obj;
  }

  /**
   * 班级idList
   * @member {Array.<Integer>} jwClazzIdList
   */
  exports.prototype['jwClazzIdList'] = undefined;
  /**
   * 只查指定年份账单数据
   * @member {Integer} year
   */
  exports.prototype['year'] = undefined;
  /**
   * xsdqzt】学生当前状态，枚举参考教务系统,在读，毕业, 默认看在读学生
   * @member {Integer} jwStatus
   */
  exports.prototype['jwStatus'] = undefined;
  /**
   * 只查欠费学生
   * @member {Boolean} owedFee
   */
  exports.prototype['owedFee'] = undefined;
  /**
   * 只查某个收费类别欠费学生 1：学宿代，2：保险，3：服装费，4：服装费补差，5：床上用品，6：体检费
   * @member {Integer} owedFeeCategory
   */
  exports.prototype['owedFeeCategory'] = undefined;



  export default exports;
;


