<template>
  <div>
    <a-spin :spinning="loading">
      <van-nav-bar
        :title="navTitle"
        left-text="返回"
        left-arrow
        :fixed="true"
        :placeholder="true"
        @click-left="() => {$router.back()}"/>
        <van-field
          readonly
          name="picker"
          :value="currentCompany.name"
          label="学校"
        />

        <van-field
          readonly
          clickable
          name="picker"
          :value="deptName"
          label="分院"
          placeholder="点击选择分院"
          @click="showDeptPicker = true"
        />
        <van-popup v-model="showDeptPicker" position="bottom">
          <van-picker
            show-toolbar
            :columns="deptColumns"
            @confirm="selectDept"
            @cancel="showDeptPicker = false"
          />
        </van-popup>

        <van-field
          readonly
          clickable
          name="picker"
          :value="majorName"
          label="专业"
          placeholder="点击选择专业"
          @click="showMajorPicker = true"
        />
        <van-popup v-model="showMajorPicker" position="bottom">
          <van-picker
            show-toolbar
            :columns="majorColumns"
            @confirm="selectMajor"
            @cancel="showMajorPicker = false"
          />
        </van-popup>

        <van-field
          readonly
          clickable
          name="picker"
          :value="clazzName"
          label="班级"
          placeholder="点击选择班级"
          @click="showClazzPicker = true"
        />
        <van-popup v-model="showClazzPicker" position="bottom">
          <van-picker
            show-toolbar
            :columns="clazzColumns"
            @confirm="selectClazz"
            @cancel="showClazzPicker = false"
          />
        </van-popup>

        <van-field
          readonly
          clickable
          name="picker"
          :value="params.year"
          label="账单年份"
          placeholder="点击选择账单年份"
          @click="showBillYearPicker = true"
          clearable
        >
          <span slot="text" @click="clearBillYear">如何获取</span>
        </van-field>
        <van-popup v-model="showBillYearPicker" position="bottom">
          <van-picker
            show-toolbar
            :columns="billYearColumns"
            @confirm="selectBillYear"
            @cancel="showBillYearPicker = false"
          />
        </van-popup>

        <van-field
          readonly
          clickable
          name="picker"
          :value="params.gradeList.join(',')"
          label="年级"
          placeholder="点击选择年级"
          @click="showGradeModal = true"
        />

        <van-popup closeable v-model="showGradeModal" position="bottom">
          <van-checkbox-group v-model="params.gradeList">
            <van-checkbox style="margin-top: 20px;margin-bottom: 20px;" v-for="grade in gradeListDict" :key="grade" :name="grade">{{grade}}</van-checkbox>
          </van-checkbox-group>
        </van-popup>

        <van-field
          readonly
          clickable
          name="picker"
          :value="feeDategoryStr"
          label="统计费用分类"
          placeholder="点击选统计费用分类"
          @click="showFeeDategoryModal = true"
        />
        <van-popup closeable v-model="showFeeDategoryModal" position="bottom">
          <van-checkbox-group v-model="params.feeDategoryList">
            <van-checkbox style="margin-top: 20px;margin-bottom: 20px;" v-for="feeDategory in feeDategoryListDict" :key="feeDategory.id" :name="feeDategory.id">{{feeDategory.label}}</van-checkbox>
          </van-checkbox-group>
        </van-popup>

        <van-button type="info" @click="onRefresh" block>查询</van-button>
    </a-spin>
    <van-pull-refresh style="margin-top: 20px;" v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad">
        <van-cell-group>
          <van-cell :clickable="true" :to="{name: 'clazzStudentStatic', params: student}" v-for="student in list" :key="student.code" :title="student.realname" :label="student.code">
            <template #right-icon>
              <span style="color: red" v-if="student.sumFee && student.sumFee.totalFee">欠费金额：{{ student.sumFee.totalFee.owedFee / 100 }}</span>
              <span v-else style="color: #969799">无欠费</span>
            </template>
          </van-cell>
        </van-cell-group>
      </van-list>
    </van-pull-refresh>
  </div>
</template>
  
<script>
import { genGradeListDict, genFeeDategoryListDict, genBillYear } from '@/utils/constants.js'
import {mapState} from "vuex";
const clone = require('rfdc')()
import FeeApi from '@/api/src/bill/api/FeeApi'
import CommonApi from '@/api/src/common/api/CommonApi'
const feeApi = new FeeApi()
const commonApi = new CommonApi()

// let companyTree = {
//   id: 1,
//   name: '西南航院',
//   deptList: [{
//     id: 11,
//     name: '空乘学院',
//     majorList: [{
//       id: 111,
//       name: '空中乘务',
//       ClassList: [{
//         id: 1111,
//         name: '空乘1班'
//       }]
//     }]
//   }]
// }

export default {
  name: 'studentList',
  data() {
    return {
      currentCompany: {},
      showDeptPicker: false,
      deptName: undefined,
      deptColumns: [],

      showMajorPicker: false,
      majorName: undefined,
      majorColumns: [],

      showClazzPicker: false,
      clazzName: undefined,
      clazzColumns: [],

      showBillYearPicker: false,
      billYearColumns: genBillYear(),

      showGradeModal: false,

      showFeeDategoryModal: false,
      feeDategoryStr: undefined,

      navTitle: '学生欠费',
      loading: false,
      finished: true,
      refreshing: false,
      gradeListDict: genGradeListDict(),
      feeDategoryListDict: genFeeDategoryListDict(),
      params: {
        companyId: undefined,
        jwDeptId: undefined,
        jwMajorId: undefined,
        jwClazzId: undefined,
        year: undefined,
        jwStatus: undefined,
        owedFee: true,
        feeDategoryList: [],
        gradeList: genGradeListDict(),
      },
      list: [],
      page: 0,
      total: 0,
    }
  },
  computed: mapState({
    companyUser: state => state.security.companyUser,
  }),
  watch: {
    'params.feeDategoryList.length': function(val) {
      let feeDategoryLabelList = []
      for(let i = 0; i < this.params.feeDategoryList.length; i++) {
        for(let j = 0; j < this.feeDategoryListDict.length; j++) {
          if(this.params.feeDategoryList[i] == this.feeDategoryListDict[j].id){
            feeDategoryLabelList.push(this.feeDategoryListDict[j].label)
          }
        }
      }
      this.feeDategoryStr = feeDategoryLabelList.join(',')
      let query = Object.assign({}, this.$route.query, {feeDategoryList: this.params.feeDategoryList})
      this.$router.replace({ query: query });
    },
    'params.gradeList.length': function(val) {
      let query = Object.assign({}, this.$route.query, {gradeList: this.params.gradeList})
      this.$router.replace({ query: query });
    }
  },
  async mounted() {
    this.loading = true
    let companyTree = await commonApi.getCompanyTree()
    this.currentCompany = companyTree
    this.deptList = companyTree.deptList
    this.deptColumns = companyTree.deptList.map(item => {return {id: item.id, name: item.name, text: item.name, majorList: item.majorList}})


    console.log(this.companyUser)
    console.log(this.$route.query)
    this.params.companyId = this.companyUser.company.id
    let routerParams = this.$route.query
    if(routerParams.gradeList) {
      this.params.gradeList = routerParams.gradeList
    }
    if(routerParams.feeDategoryList) {
      if(typeof routerParams.feeDategoryList == 'string') {
        routerParams.feeDategoryList = [routerParams.feeDategoryList]
        let query = Object.assign({}, this.$route.query, {feeDategoryList: routerParams.feeDategoryList})
        this.$router.replace({ query: query });
      }
      this.params.feeDategoryList = routerParams.feeDategoryList
      let feeDategoryLabelList = []
      for(let i = 0; i < this.params.feeDategoryList.length; i++) {
        for(let j = 0; j < this.feeDategoryListDict.length; j++) {
          console.log(this.params.feeDategoryList[i], this.feeDategoryListDict[j].id)
          if(this.params.feeDategoryList[i] == this.feeDategoryListDict[j].id){
            feeDategoryLabelList.push(this.feeDategoryListDict[j].label)
          }
        }
      }
      console.log(feeDategoryLabelList)
      this.feeDategoryStr = feeDategoryLabelList.join(',')
    }
    if(routerParams.jwDeptId) {
      let dept = this.deptColumns.find(item => {return item.id == routerParams.jwDeptId})
      this.selectDept(dept)
      if(routerParams.jwMajorId) {
        let major = dept.majorList.map(item => {return {id: item.id, name: item.name, text: item.name, ClassList: item.ClassList}}).find(item => {return item.id == routerParams.jwMajorId})
        this.selectMajor(major)
        if(routerParams.jwClazzId) {
          let clazz = major.ClassList.find(item => {return item.id == routerParams.jwClazzId})
          this.selectClazz(clazz)
        }
      }
    }
    if(routerParams.year) {
      this.selectBillYear(routerParams.year)
    }
    console.log(this.params)
    console.log(this.$route.query.deptId)
    this.loading = false
  },
  methods: {
    clearBillYear() {
      console.log('clearBillYear')
    },
    selectDept(dept) {
      console.log(dept)
      this.params.jwDeptId = dept.id
      this.deptName = dept.name;
      this.majorColumns = dept.majorList.map(item => {return {id: item.id, name: item.name, text: item.name, ClassList: item.ClassList}})
      this.selectMajor({})
      let query = Object.assign({}, this.$route.query, {jwDeptId: dept.id})
      this.$router.replace({ query: query });
      this.showDeptPicker = false;
    },
    selectMajor(major) {
      console.log(major)
      this.params.jwMajorId = major.id
      this.majorName = major.name;
      this.clazzColumns = major.ClassList ? major.ClassList.map(item => {return {id: item.id, name: item.name, text: item.name}}) : []
      this.selectClazz({})
      let query = Object.assign({}, this.$route.query, {jwMajorId: major.id})
      this.$router.replace({ query: query });
      this.showMajorPicker = false;
    },
    selectClazz(clazz) {
      console.log(clazz)
      this.params.jwClazzId = clazz.id
      this.clazzName = clazz.name;
      let query = Object.assign({}, this.$route.query, {jwClazzId: clazz.id})
      this.$router.replace({ query: query });
      this.showClazzPicker = false;
    },
    selectBillYear(value) {
      this.params.year = value;
      let query = Object.assign({}, this.$route.query, {year: value})
      this.$router.replace({ query: query });
      this.showBillYearPicker = false;
    },

    async onLoad() {
      this.loading = true;
      await this.getDate(this.page + 1)
      // this.finished = true;
      this.loading = false;
    },
    async onRefresh() {
      this.finished = false;
      this.list = [];
      this.total = 0
      this.page = 0
      await this.onLoad();
    },
    async getDate(page) {
      console.log(this.params)
      let resp = await feeApi.studentStat(page, {
        companyId: this.params.companyId,
        jwDeptId: this.params.jwDeptId,
        jwMajorId: this.params.jwMajorId,
        jwClazzId: this.params.jwClazzId,
        year: this.params.year,
        jwStatus: this.params.jwStatus,
        owedFee: this.params.owedFee,
        feeDategoryList: this.params.feeDategoryList,
        gradeList: this.params.gradeList
      })
      this.page = page
      this.total = resp.total
      if(page >= resp.pages){
        this.finished = true
      }
      if(this.page == 1) {
        this.list = [];
        this.refreshing = false;
        this.list = resp.list
      }else{
        let list = clone(this.list)
        list = list.concat(resp.list)
        this.list = list
      }
    }
  }
}
</script>
  
<style>

</style>