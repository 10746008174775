<template>
  <div>
    <van-nav-bar 
      title="缴费进度管理"
      :fixed="true"
      :placeholder="true"/>
    <van-action-sheet description="请选择院校/组织" :close-on-click-overlay="false"	v-model="showCompanyChoice" :actions="companyList" @select="selectCompany" />
  </div>
</template>

<script>
import * as dd from 'dingtalk-jsapi'
import {mapState} from "vuex";
import TokenApi from '@/api/src/token/api/TokenApi'
import FeeAccountApi from '@/api/src/account/api/FeeAccountApi'

const tokenApi = new TokenApi()
const feeAccountApi = new FeeAccountApi()
export default {
  name: 'Login',
  data() {
    return {
      showCompanyChoice: false,
      companyList: [],
    }
  },
  computed: mapState({
    user: state => state.security.user,
  }),
  mounted() {
    dd.ready(() => {
      console.log('dd ready')
      // dd.ready参数为回调函数，在环境准备就绪时触发，jsapi的调用需要保证在该回调函数触发后调用，否则无效。
      this.$toast.loading({
        message: "权限获取中...",
        duration: 0,
      });
      dd.runtime.permission.requestAuthCode({
          corpId: "dinge4bc89cece3b9e2835c2f4657eb6378f",
          onSuccess: async (result) => {
            console.log(result)
            let resp = await tokenApi.getTokenByH5DingCode({
              app: 'FEE',
              code: result.code
            })
            console.log(resp)
            if(resp.companyOrgList && resp.companyOrgList.length > 0){
              this.$store.commit('security/changeUser', resp)
              this.companyList = resp.companyOrgList
              this.showCompanyChoice = true
            }
            this.$toast.clear()
          },
          onFail : (err) => {
            console.log('dingding error')
            console.log(err)
          }
    
      });
    });
  },
  methods: {
    async selectCompany(company) {
      this.showCompanyChoice = false
      this.$toast.loading({
        message: "登录中...",
        duration: 0,
      });
      let resp = await feeAccountApi.loginCompany({
        companyId: company.id,
        token: this.user.token
      })
      console.log('selectCompany', resp)
      this.$store.commit('security/changeCompanyUser', resp)
      dd.util.domainStorage.setItem({
        name: 'token' , // 存储信息的key值
        value: resp.token, // 存储信息的Value值
        onSuccess: (info) => {
          this.$toast.clear()
          this.$router.replace({name: 'home'})
        },
        onFail: (err) => {
          this.$toast.clear()
        }
      });
    }
  }
}
</script>

<style>

</style>