/**
 * 班级接口
 * 班级接口API
 *
 * OpenAPI spec version: 0.2.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import ApiClient from '../../ApiClient';




  /**
   * The JwClazzVo model module.
   * @module clazz/model/JwClazzVo
   * @version 0.2.0
   */

  /**
   * Constructs a new <code>JwClazzVo</code>.
   * @alias module:clazz/model/JwClazzVo
   * @class
   */
  var exports = function() {
    var _this = this;






















  };

  /**
   * Constructs a <code>JwClazzVo</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:clazz/model/JwClazzVo} obj Optional instance to populate.
   * @return {module:clazz/model/JwClazzVo} The populated <code>JwClazzVo</code> instance.
   */
  exports.constructFromObject = function(data, obj) {
    if (data) {
      obj = obj || new exports();

      if (Object.prototype.hasOwnProperty.call(data, 'id')) {
        obj['id'] = ApiClient.convertToType(data['id'], 'Integer');
      }
      if (Object.prototype.hasOwnProperty.call(data, 'campusId')) {
        obj['campusId'] = ApiClient.convertToType(data['campusId'], 'Integer');
      }
      if (Object.prototype.hasOwnProperty.call(data, 'campanyId')) {
        obj['campanyId'] = ApiClient.convertToType(data['campanyId'], 'Integer');
      }
      if (Object.prototype.hasOwnProperty.call(data, 'cxId')) {
        obj['cxId'] = ApiClient.convertToType(data['cxId'], 'String');
      }
      if (Object.prototype.hasOwnProperty.call(data, 'code')) {
        obj['code'] = ApiClient.convertToType(data['code'], 'String');
      }
      if (Object.prototype.hasOwnProperty.call(data, 'name')) {
        obj['name'] = ApiClient.convertToType(data['name'], 'String');
      }
      if (Object.prototype.hasOwnProperty.call(data, 'yxid')) {
        obj['yxid'] = ApiClient.convertToType(data['yxid'], 'String');
      }
      if (Object.prototype.hasOwnProperty.call(data, 'jwDeptId')) {
        obj['jwDeptId'] = ApiClient.convertToType(data['jwDeptId'], 'Integer');
      }
      if (Object.prototype.hasOwnProperty.call(data, 'zyid')) {
        obj['zyid'] = ApiClient.convertToType(data['zyid'], 'String');
      }
      if (Object.prototype.hasOwnProperty.call(data, 'jwMajorId')) {
        obj['jwMajorId'] = ApiClient.convertToType(data['jwMajorId'], 'Integer');
      }
      if (Object.prototype.hasOwnProperty.call(data, 'grade')) {
        obj['grade'] = ApiClient.convertToType(data['grade'], 'String');
      }
      if (Object.prototype.hasOwnProperty.call(data, 'xqid')) {
        obj['xqid'] = ApiClient.convertToType(data['xqid'], 'String');
      }
      if (Object.prototype.hasOwnProperty.call(data, 'jwStatus')) {
        obj['jwStatus'] = ApiClient.convertToType(data['jwStatus'], 'Integer');
      }
      if (Object.prototype.hasOwnProperty.call(data, 'kyzt')) {
        obj['kyzt'] = ApiClient.convertToType(data['kyzt'], 'String');
      }
      if (Object.prototype.hasOwnProperty.call(data, 'updateTime')) {
        obj['updateTime'] = ApiClient.convertToType(data['updateTime'], 'String');
      }
      if (Object.prototype.hasOwnProperty.call(data, 'teacherJwUserId')) {
        obj['teacherJwUserId'] = ApiClient.convertToType(data['teacherJwUserId'], 'Integer');
      }
      if (Object.prototype.hasOwnProperty.call(data, 'bzrgh')) {
        obj['bzrgh'] = ApiClient.convertToType(data['bzrgh'], 'String');
      }
      if (Object.prototype.hasOwnProperty.call(data, 'bzrxm')) {
        obj['bzrxm'] = ApiClient.convertToType(data['bzrxm'], 'String');
      }
      if (Object.prototype.hasOwnProperty.call(data, 'gmtCreate')) {
        obj['gmtCreate'] = ApiClient.convertToType(data['gmtCreate'], 'Date');
      }
      if (Object.prototype.hasOwnProperty.call(data, 'status')) {
        obj['status'] = ApiClient.convertToType(data['status'], 'Integer');
      }
      if (Object.prototype.hasOwnProperty.call(data, 'gmtModified')) {
        obj['gmtModified'] = ApiClient.convertToType(data['gmtModified'], 'Date');
      }
    }
    return obj;
  }

  /**
   * 班级ID
   * @member {Integer} id
   */
  exports.prototype['id'] = undefined;
  /**
   * 校区ID
   * @member {Integer} campusId
   */
  exports.prototype['campusId'] = undefined;
  /**
   * 学校ID
   * @member {Integer} campanyId
   */
  exports.prototype['campanyId'] = undefined;
  /**
   * 【id】
   * @member {String} cxId
   */
  exports.prototype['cxId'] = undefined;
  /**
   * 【bjbh】班级编号
   * @member {String} code
   */
  exports.prototype['code'] = undefined;
  /**
   * 【bjmc】班级名称
   * @member {String} name
   */
  exports.prototype['name'] = undefined;
  /**
   * 【yxid】院系ID
   * @member {String} yxid
   */
  exports.prototype['yxid'] = undefined;
  /**
   * 教务院系ID
   * @member {Integer} jwDeptId
   */
  exports.prototype['jwDeptId'] = undefined;
  /**
   * 【zyid】专业ID
   * @member {String} zyid
   */
  exports.prototype['zyid'] = undefined;
  /**
   * 教务专业ID
   * @member {Integer} jwMajorId
   */
  exports.prototype['jwMajorId'] = undefined;
  /**
   * 【nj】年级
   * @member {String} grade
   */
  exports.prototype['grade'] = undefined;
  /**
   * 【xqid】校区ID
   * @member {String} xqid
   */
  exports.prototype['xqid'] = undefined;
  /**
   * 【kyzt】可用状态(1:是；0:否；)
   * @member {Integer} jwStatus
   */
  exports.prototype['jwStatus'] = undefined;
  /**
   * 【kyzt】可用状态(1:是；0:否；)
   * @member {String} kyzt
   */
  exports.prototype['kyzt'] = undefined;
  /**
   * 更新时间
   * @member {String} updateTime
   */
  exports.prototype['updateTime'] = undefined;
  /**
   * 班主任教务用户ID
   * @member {Integer} teacherJwUserId
   */
  exports.prototype['teacherJwUserId'] = undefined;
  /**
   * 【bzrgh】班主任工号
   * @member {String} bzrgh
   */
  exports.prototype['bzrgh'] = undefined;
  /**
   * 【bzrxm】班主任姓名
   * @member {String} bzrxm
   */
  exports.prototype['bzrxm'] = undefined;
  /**
   * 创建时间
   * @member {Date} gmtCreate
   */
  exports.prototype['gmtCreate'] = undefined;
  /**
   * 1：可用；0：禁用
   * @member {Integer} status
   */
  exports.prototype['status'] = undefined;
  /**
   * 修改时间
   * @member {Date} gmtModified
   */
  exports.prototype['gmtModified'] = undefined;



  export default exports;
;


