/**
 * 用户接口
 * 用户接口API
 *
 * OpenAPI spec version: 0.2.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import ApiClient from '../../ApiClient';




  /**
   * The PwdLoginForm model module.
   * @module token/model/PwdLoginForm
   * @version 0.2.0
   */

  /**
   * Constructs a new <code>PwdLoginForm</code>.
   * @alias module:token/model/PwdLoginForm
   * @class
   * @param app {String} 泛美教育：EDU，收费统计，FEE
   * @param phoneNum {String} 手机号
   * @param password {String} 密码
   */
  var exports = function(app, phoneNum, password) {
    var _this = this;

    _this['app'] = app;
    _this['phoneNum'] = phoneNum;
    _this['password'] = password;
  };

  /**
   * Constructs a <code>PwdLoginForm</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:token/model/PwdLoginForm} obj Optional instance to populate.
   * @return {module:token/model/PwdLoginForm} The populated <code>PwdLoginForm</code> instance.
   */
  exports.constructFromObject = function(data, obj) {
    if (data) {
      obj = obj || new exports();

      if (Object.prototype.hasOwnProperty.call(data, 'app')) {
        obj['app'] = ApiClient.convertToType(data['app'], 'String');
      }
      if (Object.prototype.hasOwnProperty.call(data, 'phoneNum')) {
        obj['phoneNum'] = ApiClient.convertToType(data['phoneNum'], 'String');
      }
      if (Object.prototype.hasOwnProperty.call(data, 'password')) {
        obj['password'] = ApiClient.convertToType(data['password'], 'String');
      }
    }
    return obj;
  }

  /**
   * 泛美教育：EDU，收费统计，FEE
   * @member {String} app
   */
  exports.prototype['app'] = undefined;
  /**
   * 手机号
   * @member {String} phoneNum
   */
  exports.prototype['phoneNum'] = undefined;
  /**
   * 密码
   * @member {String} password
   */
  exports.prototype['password'] = undefined;



  export default exports;
;


