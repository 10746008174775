const state = {
  user: {},
  companyUser: {}
}

const mutations = {
  changeUser(state, user) {
    state.user = user
  },
  changeCompanyUser(state, companyUser) {
    state.companyUser = companyUser
  },
}

const actions = {
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}