/**
 * 财务接口
 * 财务接口API
 *
 * OpenAPI spec version: 0.2.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import ApiClient from '../../ApiClient';




  /**
   * The DeptQueryForm model module.
   * @module bill/model/DeptQueryForm
   * @version 0.2.0
   */

  /**
   * Constructs a new <code>DeptQueryForm</code>.
   * @alias module:bill/model/DeptQueryForm
   * @class
   * @param companyId {Integer} 院校id
   * @param queryDate {Date} 查询日期
   */
  var exports = function(companyId, queryDate) {
    var _this = this;

    _this['companyId'] = companyId;

    _this['queryDate'] = queryDate;


  };

  /**
   * Constructs a <code>DeptQueryForm</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:bill/model/DeptQueryForm} obj Optional instance to populate.
   * @return {module:bill/model/DeptQueryForm} The populated <code>DeptQueryForm</code> instance.
   */
  exports.constructFromObject = function(data, obj) {
    if (data) {
      obj = obj || new exports();

      if (Object.prototype.hasOwnProperty.call(data, 'companyId')) {
        obj['companyId'] = ApiClient.convertToType(data['companyId'], 'Integer');
      }
      if (Object.prototype.hasOwnProperty.call(data, 'schoolYear')) {
        obj['schoolYear'] = ApiClient.convertToType(data['schoolYear'], 'Integer');
      }
      if (Object.prototype.hasOwnProperty.call(data, 'queryDate')) {
        obj['queryDate'] = ApiClient.convertToType(data['queryDate'], 'Date');
      }
      if (Object.prototype.hasOwnProperty.call(data, 'feeDategoryList')) {
        obj['feeDategoryList'] = ApiClient.convertToType(data['feeDategoryList'], ['Integer']);
      }
      if (Object.prototype.hasOwnProperty.call(data, 'gradeList')) {
        obj['gradeList'] = ApiClient.convertToType(data['gradeList'], ['Integer']);
      }
    }
    return obj;
  }

  /**
   * 院校id
   * @member {Integer} companyId
   */
  exports.prototype['companyId'] = undefined;
  /**
   * 学年
   * @member {Integer} schoolYear
   */
  exports.prototype['schoolYear'] = undefined;
  /**
   * 查询日期
   * @member {Date} queryDate
   */
  exports.prototype['queryDate'] = undefined;
  /**
   * 费用类型列表(不传默认查询所有 123456)
   * @member {Array.<Integer>} feeDategoryList
   */
  exports.prototype['feeDategoryList'] = undefined;
  /**
   * 年级列表（不传默认查询当前学年往前5年，含当前学年）
   * @member {Array.<Integer>} gradeList
   */
  exports.prototype['gradeList'] = undefined;



  export default exports;
;


