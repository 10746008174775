/**
 * 班级接口
 * 班级接口API
 *
 * OpenAPI spec version: 0.2.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import ApiClient from '../../ApiClient';
import JwClazzVo from '../../clazz/model/JwClazzVo';
  /**
   * Clazz service.
   * @module clazz/api/ClazzApi
   * @version 0.2.0
   */

  /**
   * Constructs a new ClazzApi. 
   * @alias module:clazz/api/ClazzApi
   * @class
   * @param {module:ApiClient} apiClient Optional API client implementation to use,
   * default to {@link module:ApiClient#instance} if unspecified.
   */
  var exports = function(apiClient) {
    this.apiClient = apiClient || ApiClient.instance;



    /**
     * 当前人拥有的班级
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:clazz/model/JwClazzVo>}
     */
this.currentClassList = function() {
      var postBody = null;


      var pathParams = {
      };
      var queryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var isPublic = false;

      var authNames = [];
      var contentTypes = [];
      var accepts = [];
      var returnType = [JwClazzVo];
      return this.apiClient.callApi(
        '/api/clazz/current_class_list', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType,isPublic
      );
    }
  };

  export default exports;
