/**
 * 用户接口
 * 用户接口API
 *
 * OpenAPI spec version: 0.2.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import ApiClient from '../../ApiClient';




  /**
   * The CompanyLoginVo model module.
   * @module token/model/CompanyLoginVo
   * @version 0.2.0
   */

  /**
   * Constructs a new <code>CompanyLoginVo</code>.
   * @alias module:token/model/CompanyLoginVo
   * @class
   */
  var exports = function() {
    var _this = this;







  };

  /**
   * Constructs a <code>CompanyLoginVo</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:token/model/CompanyLoginVo} obj Optional instance to populate.
   * @return {module:token/model/CompanyLoginVo} The populated <code>CompanyLoginVo</code> instance.
   */
  exports.constructFromObject = function(data, obj) {
    if (data) {
      obj = obj || new exports();

      if (Object.prototype.hasOwnProperty.call(data, 'id')) {
        obj['id'] = ApiClient.convertToType(data['id'], 'Integer');
      }
      if (Object.prototype.hasOwnProperty.call(data, 'abbr')) {
        obj['abbr'] = ApiClient.convertToType(data['abbr'], 'String');
      }
      if (Object.prototype.hasOwnProperty.call(data, 'name')) {
        obj['name'] = ApiClient.convertToType(data['name'], 'String');
      }
      if (Object.prototype.hasOwnProperty.call(data, 'code')) {
        obj['code'] = ApiClient.convertToType(data['code'], 'String');
      }
      if (Object.prototype.hasOwnProperty.call(data, 'logoUrl')) {
        obj['logoUrl'] = ApiClient.convertToType(data['logoUrl'], 'String');
      }
      if (Object.prototype.hasOwnProperty.call(data, 'currentLogin')) {
        obj['currentLogin'] = ApiClient.convertToType(data['currentLogin'], 'Integer');
      }
    }
    return obj;
  }

  /**
   * id
   * @member {Integer} id
   */
  exports.prototype['id'] = undefined;
  /**
   * 简称(突出显示)
   * @member {String} abbr
   */
  exports.prototype['abbr'] = undefined;
  /**
   * 名称
   * @member {String} name
   */
  exports.prototype['name'] = undefined;
  /**
   * 编号
   * @member {String} code
   */
  exports.prototype['code'] = undefined;
  /**
   * 公司logo路径
   * @member {String} logoUrl
   */
  exports.prototype['logoUrl'] = undefined;
  /**
   * 当前登录 1是，-1否， 0新增
   * @member {Integer} currentLogin
   */
  exports.prototype['currentLogin'] = undefined;



  export default exports;
;


