/**
 * 公共接口
 * 公共接口API
 *
 * OpenAPI spec version: 0.2.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import ApiClient from '../../ApiClient';
import CompanyTreeVo from '../../common/model/CompanyTreeVo';
  /**
   * Common service.
   * @module common/api/CommonApi
   * @version 0.2.0
   */

  /**
   * Constructs a new CommonApi. 
   * @alias module:common/api/CommonApi
   * @class
   * @param {module:ApiClient} apiClient Optional API client implementation to use,
   * default to {@link module:ApiClient#instance} if unspecified.
   */
  var exports = function(apiClient) {
    this.apiClient = apiClient || ApiClient.instance;



    /**
     * 学校组织结构 学校-院系-专业-班级
     * @param {Object} opts Optional parameters
     * @param {Integer} opts.companyId 学校id (不传默认当前人角色的的学校id)
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:common/model/CompanyTreeVo}
     */
this.getCompanyTree = function(opts) {
      opts = opts || {};
      var postBody = null;


      var pathParams = {
      };
      var queryParams = {
        'companyId': opts['companyId']
      };
      var headerParams = {
      };
      var formParams = {
      };

      var isPublic = false;

      var authNames = [];
      var contentTypes = [];
      var accepts = [];
      var returnType = CompanyTreeVo;
      return this.apiClient.callApi(
        '/api/common/getCompanyTree', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType,isPublic
      );
    }
  };

  export default exports;
