/**
 * 财务接口
 * 财务接口API
 *
 * OpenAPI spec version: 0.2.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import ApiClient from '../../ApiClient';




  /**
   * The FeeVo model module.
   * @module bill/model/FeeVo
   * @version 0.2.0
   */

  /**
   * Constructs a new <code>FeeVo</code>.
   * @alias module:bill/model/FeeVo
   * @class
   */
  var exports = function() {
    var _this = this;






  };

  /**
   * Constructs a <code>FeeVo</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:bill/model/FeeVo} obj Optional instance to populate.
   * @return {module:bill/model/FeeVo} The populated <code>FeeVo</code> instance.
   */
  exports.constructFromObject = function(data, obj) {
    if (data) {
      obj = obj || new exports();

      if (Object.prototype.hasOwnProperty.call(data, 'totalFee')) {
        obj['totalFee'] = ApiClient.convertToType(data['totalFee'], 'Integer');
      }
      if (Object.prototype.hasOwnProperty.call(data, 'discountFee')) {
        obj['discountFee'] = ApiClient.convertToType(data['discountFee'], 'Integer');
      }
      if (Object.prototype.hasOwnProperty.call(data, 'deferredFee')) {
        obj['deferredFee'] = ApiClient.convertToType(data['deferredFee'], 'Integer');
      }
      if (Object.prototype.hasOwnProperty.call(data, 'owedFee')) {
        obj['owedFee'] = ApiClient.convertToType(data['owedFee'], 'Integer');
      }
      if (Object.prototype.hasOwnProperty.call(data, 'paidFee')) {
        obj['paidFee'] = ApiClient.convertToType(data['paidFee'], 'Integer');
      }
    }
    return obj;
  }

  /**
   * 应缴金额，单位分
   * @member {Integer} totalFee
   */
  exports.prototype['totalFee'] = undefined;
  /**
   * 减免金额
   * @member {Integer} discountFee
   */
  exports.prototype['discountFee'] = undefined;
  /**
   * 缓交金额
   * @member {Integer} deferredFee
   */
  exports.prototype['deferredFee'] = undefined;
  /**
   * 欠费金额
   * @member {Integer} owedFee
   */
  exports.prototype['owedFee'] = undefined;
  /**
   * 实收金额
   * @member {Integer} paidFee
   */
  exports.prototype['paidFee'] = undefined;



  export default exports;
;


