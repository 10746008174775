import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Antd from 'ant-design-vue'
import VueEasytable from "vue-easytable"
import 'ant-design-vue/dist/antd.css'
import "vue-easytable/libs/theme-default/index.css"
/**
 * vant
 */
import 'vant/lib/index.css'
import { Button } from 'vant';
Vue.use(Button);
import { Grid, GridItem } from 'vant';
Vue.use(Grid);
Vue.use(GridItem);
import { NavBar } from 'vant';
Vue.use(NavBar);
import { Toast } from 'vant';
Toast.setDefaultOptions('loading', { forbidClick: true });
Vue.use(Toast);
import { List } from 'vant';
Vue.use(List);
import { Cell, CellGroup } from 'vant';
Vue.use(Cell);
Vue.use(CellGroup);
import { Icon } from 'vant';
Vue.use(Icon);
import { ActionSheet } from 'vant';
Vue.use(ActionSheet);
import { PullRefresh } from 'vant';
Vue.use(PullRefresh);
import { Tab, Tabs } from 'vant';
Vue.use(Tab);
Vue.use(Tabs);
import { Col, Row } from 'vant';
Vue.use(Col);
Vue.use(Row);
import { Tag } from 'vant';
Vue.use(Tag);
import { Dialog } from 'vant';
Vue.use(Dialog);
import { Calendar } from 'vant';
Vue.use(Calendar);
import { Popup } from 'vant';
Vue.use(Popup);
import { Picker } from 'vant';
Vue.use(Picker);
import { Loading } from 'vant';
Vue.use(Loading);
import { Checkbox, CheckboxGroup } from 'vant';
Vue.use(Checkbox).use(CheckboxGroup);
import { Form } from 'vant';
import { Field } from 'vant';
Vue.use(Form);
Vue.use(Field);

Vue.config.productionTip = false

Vue.use(Antd)
Vue.use(VueEasytable)



new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
