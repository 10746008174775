<template>
  <div class="home" style="margin-top: 16px;margin-bottom: 16px;">
    <a-form-model layout="inline">
      <a-row>
        <!-- <a-col :span="4">
          <a-form-model-item label="学年">
            <a-select style="width: 160px" v-model="params.year">
              <a-select-option :value="year" v-for="year in gradeList" :key="year">
                {{year}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col> -->
        <a-col :span="4">
          <a-form-model-item label="班级">
            <a-select style="width: 160px" mode="multiple" v-model="params.jwClazzIdList">
              <a-select-option :value="clazz.id" v-for="clazz in classList" :key="clazz.id">
                {{clazz.name}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="4">
          <a-form-model-item label="学生当前状态">
            <a-select style="width: 160px" v-model="params.jwStatus">
              <a-select-option :value="1">
                在读
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <!-- <a-col :span="4">
          <a-form-model-item label="只查欠费学生">
            <a-select style="width: 160px" mode="multiple" v-model="params.jwStatus">
              <a-select-option :value="1">
                是
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col> -->
        <!-- <a-col :span="4">
          <a-form-model-item label="姓名">
            <a-input v-model="params.name" placeholder="学生姓名" />
          </a-form-model-item>
        </a-col> -->
      </a-row>
      <a-row>
        <a-col :span="4" :offset="20">
          <a-button type="primary">查询</a-button>
        </a-col>
      </a-row>
    </a-form-model>
    <a-spin :spinning="loading">
      <ve-table :columns="columns" 
        style="margin-top: 8px;"
        :table-data="data" 
        :scroll-width="1500" 
        max-height="calc(100vh - 112px)" 
        :fixed-header="true"
        :border-around="true"
        :border-x="true"
        :border-y="true"
        :column-width-resize-option="columnWidthResizeOption"/>
    </a-spin>
  </div>
</template>

<script>
import ClazzApi from '@/api/src/clazz/api/ClazzApi'
import FeeApi from '@/api/src/bill/api/FeeApi'
const clazzApi = new ClazzApi()
const feeApi = new FeeApi()
const columns2 = [
  { title: '姓名', width: 140, field: 'realname', key: 'realname', fixed: 'left', align: "left", },
  { title: '身份证号', width: 180, field: 'idNumber', key: 'idNumber', fixed: 'left' },
  { title: '性别', width: 100, field: 'gender', key: 'gender', fixed: 'left',
    renderBodyCell: ({ row, column, rowIndex }, h) => {
        if(row.gender == 1) {
          return (
            <span>
              男
            </span>
          );
        }else{
          return (
            <span>
              女
            </span>
          );
        }
      } },
  { title: '手机号', width: 120, field: 'mobile', key: 'age9992'},
  { title: '所在年级', width: 100, field: 'grade', key: 'age9993'},
  { title: '培养层次', width: 100, field: 'batchName1', key: 'age99914'},
  { title: '学制', width: 100, field: 'batchName2', key: 'age9994'},
  { title: '分院', field: 'collegeName', key: '4', width: 150, 
    renderBodyCell: ({ row, column, rowIndex }, h) => {
      return (
        <span>
          {row.jwDept.name}
        </span>
      );
    }},
  { title: '专业', field: 'majorName', key: '5', width: 150,
    renderBodyCell: ({ row, column, rowIndex }, h) => {
        return (
          <span>
            {row.jwMajor.name}
          </span>
        );
      }},
  { title: '当前班级', field: 'className', key: '6', width: 150, 
    renderBodyCell: ({ row, column, rowIndex }, h) => {
      return (
        <span>
          {row.jwClazz.name}
        </span>
      );
    }},
  { title: '累计欠费', field: 'arrears', width: 100, key: 'age9995', 
    renderBodyCell: ({ row, column, rowIndex }, h) => {
      if(row.arrears > 0) {
        return (
          <span style="color: red;">
            {row.arrears}
          </span>
        );
      }else{
        return (
          <span>
            {row.arrears}
          </span>
        );
      }
    }
  },
  {
    title: '第一学年',
    children: [
      {
        title: '缴费时间',
        field: 'prePayTime',
        key: 'age20',
        width: 100,
      },
      {
        title: '学宿代',
        children: [
          {
            title: '应缴',
            field: 'main1',
            key: 'street201',
            width: 100,
            renderBodyCell: ({ row, column, rowIndex }, h) => {
              if(row.yearFeeList && row.yearFeeList.length >= 1 && row.yearFeeList[0].category1Fee){
                return (
                  <span>
                    {row.yearFeeList[0].category1Fee.totalFee / 100}
                  </span>
                );
              }
            }
          },
          {
            title: '实缴',
            field: 'main2',
            key: 'street202',
            width: 100,
            renderBodyCell: ({ row, column, rowIndex }, h) => {
              if(row.yearFeeList && row.yearFeeList.length >= 1 && row.yearFeeList[0].category1Fee){
                return (
                  <span>
                    {row.yearFeeList[0].category1Fee.paidFee / 100}
                  </span>
                );
              }
            }
          },
          {
            title: '欠费',
            field: 'main3',
            key: 'street203',
            width: 100,
            renderBodyCell: ({ row, column, rowIndex }, h) => {
              if(row.yearFeeList && row.yearFeeList.length >= 1 && row.yearFeeList[0].category1Fee){
                return (
                  <span>
                    {row.yearFeeList[0].category1Fee.owedFee / 100}
                  </span>
                );
              }
            }
          },
        ],
      },
      {
        title: '保险',
        children: [
          {
            title: '应缴',
            field: 'street',
            key: 'street1911',
            width: 100,
            renderBodyCell: ({ row, column, rowIndex }, h) => {
              if(row.yearFeeList && row.yearFeeList.length >= 1 && row.yearFeeList[0].category2Fee){
                return (
                  <span>
                    {row.yearFeeList[0].category2Fee.totalFee / 100}
                  </span>
                );
              }
            }
          },
          {
            title: '实缴',
            field: 'street',
            key: 'street1912',
            width: 100,
            renderBodyCell: ({ row, column, rowIndex }, h) => {
              if(row.yearFeeList && row.yearFeeList.length >= 1 && row.yearFeeList[0].category2Fee){
                return (
                  <span>
                    {row.yearFeeList[0].category2Fee.paidFee / 100}
                  </span>
                );
              }
            }
          },
          {
            title: '欠费',
            field: 'street',
            key: 'street1913',
            width: 100,
            renderBodyCell: ({ row, column, rowIndex }, h) => {
              if(row.yearFeeList && row.yearFeeList.length >= 1 && row.yearFeeList[0].category2Fee){
                return (
                  <span>
                    {row.yearFeeList[0].category2Fee.owedFee / 100}
                  </span>
                );
              }
            }
          },
        ],
      },
      {
        title: '服装费',
        children: [
          {
            title: '应缴',
            field: 'street',
            key: 'street1821',
            width: 100,
            renderBodyCell: ({ row, column, rowIndex }, h) => {
              if(row.yearFeeList && row.yearFeeList.length >= 1 && row.yearFeeList[0].category3Fee){
                return (
                  <span>
                    {row.yearFeeList[0].category3Fee.totalFee / 100}
                  </span>
                );
              }
            }
          },
          {
            title: '实缴',
            field: 'street',
            key: 'street1822',
            width: 100,
            renderBodyCell: ({ row, column, rowIndex }, h) => {
              if(row.yearFeeList && row.yearFeeList.length >= 1 && row.yearFeeList[0].category3Fee){
                return (
                  <span>
                    {row.yearFeeList[0].category3Fee.paidFee / 100}
                  </span>
                );
              }
            }
          },
          {
            title: '欠费',
            field: 'street',
            key: 'street1823',
            width: 100,
            renderBodyCell: ({ row, column, rowIndex }, h) => {
              if(row.yearFeeList && row.yearFeeList.length >= 1 && row.yearFeeList[0].category3Fee){
                return (
                  <span>
                    {row.yearFeeList[0].category3Fee.owedFee / 100}
                  </span>
                );
              }
            }
          },
        ],
      },
      {
        title: '服装费补差',
        children: [
          {
            title: '应缴',
            field: 'street',
            key: 'street1731',
            width: 100,
            renderBodyCell: ({ row, column, rowIndex }, h) => {
              if(row.yearFeeList && row.yearFeeList.length >= 1 && row.yearFeeList[0].category4Fee){
                return (
                  <span>
                    {row.yearFeeList[0].category4Fee.totalFee / 100}
                  </span>
                );
              }
            }
          },
          {
            title: '实缴',
            field: 'street',
            key: 'street1732',
            width: 100,
            renderBodyCell: ({ row, column, rowIndex }, h) => {
              if(row.yearFeeList && row.yearFeeList.length >= 1 && row.yearFeeList[0].category4Fee){
                return (
                  <span>
                    {row.yearFeeList[0].category4Fee.paidFee / 100}
                  </span>
                );
              }
            }
          },
          {
            title: '欠费',
            field: 'street',
            key: 'street1733',
            width: 100,
            renderBodyCell: ({ row, column, rowIndex }, h) => {
              if(row.yearFeeList && row.yearFeeList.length >= 1 && row.yearFeeList[0].category4Fee){
                return (
                  <span>
                    {row.yearFeeList[0].category4Fee.owedFee / 100}
                  </span>
                );
              }
            }
          },
        ],
      },
      {
        title: '床上用品',
        children: [
          {
            title: '应缴',
            field: 'street',
            key: 'street1641',
            width: 100,
            renderBodyCell: ({ row, column, rowIndex }, h) => {
              if(row.yearFeeList && row.yearFeeList.length >= 1 && row.yearFeeList[0].category5Fee){
                return (
                  <span>
                    {row.yearFeeList[0].category5Fee.totalFee / 100}
                  </span>
                );
              }
            }
          },
          {
            title: '实缴',
            field: 'street',
            key: 'street1642',
            width: 100,
            renderBodyCell: ({ row, column, rowIndex }, h) => {
              if(row.yearFeeList && row.yearFeeList.length >= 1 && row.yearFeeList[0].category5Fee){
                return (
                  <span>
                    {row.yearFeeList[0].category5Fee.paidFee / 100}
                  </span>
                );
              }
            }
          },
          {
            title: '欠费',
            field: 'street',
            key: 'street1643',
            width: 100,
            renderBodyCell: ({ row, column, rowIndex }, h) => {
              if(row.yearFeeList && row.yearFeeList.length >= 1 && row.yearFeeList[0].category5Fee){
                return (
                  <span>
                    {row.yearFeeList[0].category5Fee.owedFee / 100}
                  </span>
                );
              }
            }
          },
        ],
      },
      {
        title: '体检费',
        children: [
          {
            title: '应缴',
            field: 'street',
            key: 'street1551',
            width: 100,
            renderBodyCell: ({ row, column, rowIndex }, h) => {
              if(row.yearFeeList && row.yearFeeList.length >= 1 && row.yearFeeList[0].category6Fee){
                return (
                  <span>
                    {row.yearFeeList[0].category6Fee.totalFee / 100}
                  </span>
                );
              }
            }
          },
          {
            title: '实缴',
            field: 'street',
            key: 'street1552',
            width: 100,
            renderBodyCell: ({ row, column, rowIndex }, h) => {
              if(row.yearFeeList && row.yearFeeList.length >= 1 && row.yearFeeList[0].category6Fee){
                return (
                  <span>
                    {row.yearFeeList[0].category6Fee.paidFee / 100}
                  </span>
                );
              }
            }
          },
          {
            title: '欠费',
            field: 'street',
            key: 'street1553',
            width: 100,
            renderBodyCell: ({ row, column, rowIndex }, h) => {
              if(row.yearFeeList && row.yearFeeList.length >= 1 && row.yearFeeList[0].category6Fee){
                return (
                  <span>
                    {row.yearFeeList[0].category6Fee.owedFee / 100}
                  </span>
                );
              }
            }
          },
        ],
      },
    ],
  },
  {
    title: '第二学年',
    children: [
      {
        title: '缴费时间',
        field: 'age',
        key: 'age8',
        width: 100,
      },
      {
        title: '学宿代',
        children: [
          {
            title: '应缴',
            field: 'street',
            key: 'street81',
            width: 100,
            renderBodyCell: ({ row, column, rowIndex }, h) => {
              if(row.yearFeeList && row.yearFeeList.length >= 2 && row.yearFeeList[1].category1Fee){
                return (
                  <span>
                    {row.yearFeeList[1].category1Fee.totalFee / 100}
                  </span>
                );
              }
            }
          },
          {
            title: '实缴',
            field: 'street',
            key: 'street82',
            width: 100,
            renderBodyCell: ({ row, column, rowIndex }, h) => {
              if(row.yearFeeList && row.yearFeeList.length >= 2 && row.yearFeeList[1].category1Fee){
                return (
                  <span>
                    {row.yearFeeList[1].category1Fee.paidFee / 100}
                  </span>
                );
              }
            }
          },
          {
            title: '欠费',
            field: 'street',
            key: 'street83',
            width: 100,
            renderBodyCell: ({ row, column, rowIndex }, h) => {
              if(row.yearFeeList && row.yearFeeList.length >= 2 && row.yearFeeList[1].category1Fee){
                return (
                  <span>
                    {row.yearFeeList[1].category1Fee.owedFee / 100}
                  </span>
                );
              }
            }
          },
        ],
      },
      {
        title: '保险',
        children: [
          {
            title: '应缴',
            field: 'street',
            key: 'street711',
            width: 100,
            renderBodyCell: ({ row, column, rowIndex }, h) => {
              if(row.yearFeeList && row.yearFeeList.length >= 2 && row.yearFeeList[1].category2Fee){
                return (
                  <span>
                    {row.yearFeeList[1].category2Fee.totalFee / 100}
                  </span>
                );
              }
            }
          },
          {
            title: '实缴',
            field: 'street',
            key: 'street712',
            width: 100,
            renderBodyCell: ({ row, column, rowIndex }, h) => {
              if(row.yearFeeList && row.yearFeeList.length >= 2 && row.yearFeeList[1].category2Fee){
                return (
                  <span>
                    {row.yearFeeList[1].category2Fee.paidFee / 100}
                  </span>
                );
              }
            }
          },
          {
            title: '欠费',
            field: 'street',
            key: 'street713',
            width: 100,
            renderBodyCell: ({ row, column, rowIndex }, h) => {
              if(row.yearFeeList && row.yearFeeList.length >= 2 && row.yearFeeList[1].category2Fee){
                return (
                  <span>
                    {row.yearFeeList[1].category2Fee.owedFee / 100}
                  </span>
                );
              }
            }
          },
        ],
      },
    ],
  },
  {
    title: '第三学年',
    children: [
      {
        title: '缴费时间',
        field: 'age',
        key: 'age6',
        width: 100,
      },
      {
        title: '学宿代',
        children: [
          {
            title: '应缴',
            field: 'street',
            key: 'street61',
            width: 100,
            renderBodyCell: ({ row, column, rowIndex }, h) => {
              if(row.yearFeeList && row.yearFeeList.length >= 3 && row.yearFeeList[2].category1Fee){
                return (
                  <span>
                    {row.yearFeeList[2].category1Fee.totalFee / 100}
                  </span>
                );
              }
            }
          },
          {
            title: '实缴',
            field: 'street',
            key: 'street62',
            width: 100,
            renderBodyCell: ({ row, column, rowIndex }, h) => {
              if(row.yearFeeList && row.yearFeeList.length >= 3 && row.yearFeeList[2].category1Fee){
                return (
                  <span>
                    {row.yearFeeList[2].category1Fee.paidFee / 100}
                  </span>
                );
              }
            }
          },
          {
            title: '欠费',
            field: 'street',
            key: 'street63',
            width: 100,
            renderBodyCell: ({ row, column, rowIndex }, h) => {
              if(row.yearFeeList && row.yearFeeList.length >= 3 && row.yearFeeList[2].category1Fee){
                return (
                  <span>
                    {row.yearFeeList[2].category1Fee.owedFee / 100}
                  </span>
                );
              }
            }
          },
        ],
      },
      {
        title: '保险',
        children: [
          {
            title: '应缴',
            field: 'street',
            key: 'street511',
            width: 100,
            renderBodyCell: ({ row, column, rowIndex }, h) => {
              if(row.yearFeeList && row.yearFeeList.length >= 3 && row.yearFeeList[2].category2Fee){
                return (
                  <span>
                    {row.yearFeeList[2].category2Fee.totalFee / 100}
                  </span>
                );
              }
            }
          },
          {
            title: '实缴',
            field: 'street',
            key: 'street512',
            width: 100,
            renderBodyCell: ({ row, column, rowIndex }, h) => {
              if(row.yearFeeList && row.yearFeeList.length >= 3 && row.yearFeeList[2].category2Fee){
                return (
                  <span>
                    {row.yearFeeList[2].category2Fee.paidFee / 100}
                  </span>
                );
              }
            }
          },
          {
            title: '欠费',
            field: 'street',
            key: 'street513',
            width: 100,
            renderBodyCell: ({ row, column, rowIndex }, h) => {
              if(row.yearFeeList && row.yearFeeList.length >= 3 && row.yearFeeList[2].category2Fee){
                return (
                  <span>
                    {row.yearFeeList[2].category2Fee.owedFee / 100}
                  </span>
                );
              }
            }
          },
        ],
      },
    ],
  },
  {
    title: '第四学年',
    children: [
      {
        title: '缴费时间',
        field: 'age',
        key: 'age4',
        width: 100,
      },
      {
        title: '学宿代',
        children: [
          {
            title: '应缴',
            field: 'street',
            key: 'street41',
            width: 100,
            renderBodyCell: ({ row, column, rowIndex }, h) => {
              if(row.yearFeeList && row.yearFeeList.length >= 4 && row.yearFeeList[3].category1Fee){
                return (
                  <span>
                    {row.yearFeeList[3].category1Fee.totalFee / 100}
                  </span>
                );
              }
            }
          },
          {
            title: '实缴',
            field: 'street',
            key: 'street42',
            width: 100,
            renderBodyCell: ({ row, column, rowIndex }, h) => {
              if(row.yearFeeList && row.yearFeeList.length >= 4 && row.yearFeeList[3].category1Fee){
                return (
                  <span>
                    {row.yearFeeList[3].category1Fee.paidFee / 100}
                  </span>
                );
              }
            }
          },
          {
            title: '欠费',
            field: 'street',
            key: 'street43',
            width: 100,
            renderBodyCell: ({ row, column, rowIndex }, h) => {
              if(row.yearFeeList && row.yearFeeList.length >= 4 && row.yearFeeList[3].category1Fee){
                return (
                  <span>
                    {row.yearFeeList[3].category1Fee.owedFee / 100}
                  </span>
                );
              }
            }
          },
        ],
      },
      {
        title: '保险',
        children: [
          {
            title: '应缴',
            field: 'street',
            key: 'street311',
            width: 100,
            renderBodyCell: ({ row, column, rowIndex }, h) => {
              if(row.yearFeeList && row.yearFeeList.length >= 4 && row.yearFeeList[3].category2Fee){
                return (
                  <span>
                    {row.yearFeeList[3].category2Fee.totalFee / 100}
                  </span>
                );
              }
            }
          },
          {
            title: '实缴',
            field: 'street',
            key: 'street312',
            width: 100,
            renderBodyCell: ({ row, column, rowIndex }, h) => {
              if(row.yearFeeList && row.yearFeeList.length >= 4 && row.yearFeeList[3].category2Fee){
                return (
                  <span>
                    {row.yearFeeList[3].category2Fee.paidFee / 100}
                  </span>
                );
              }
            }
          },
          {
            title: '欠费',
            field: 'street',
            key: 'street313',
            width: 100,
            renderBodyCell: ({ row, column, rowIndex }, h) => {
              if(row.yearFeeList && row.yearFeeList.length >= 4 && row.yearFeeList[3].category2Fee){
                return (
                  <span>
                    {row.yearFeeList[3].category2Fee.owedFee / 100}
                  </span>
                );
              }
            }
          },
        ],
      },
    ],
  },
  {
    title: '第五学年',
    children: [
      {
        title: '缴费时间',
        field: 'age',
        key: 'age2263',
        width: 100,
      },
      {
        title: '学宿代',
        children: [
          {
            title: '应缴',
            field: 'street',
            key: 'street21',
            width: 100,
            renderBodyCell: ({ row, column, rowIndex }, h) => {
              if(row.yearFeeList && row.yearFeeList.length >= 5 && row.yearFeeList[4].category1Fee){
                return (
                  <span>
                    {row.yearFeeList[4].category1Fee.totalFee / 100}
                  </span>
                );
              }
            }
          },
          {
            title: '实缴',
            field: 'street',
            key: 'street22',
            width: 100,
            renderBodyCell: ({ row, column, rowIndex }, h) => {
              if(row.yearFeeList && row.yearFeeList.length >= 5 && row.yearFeeList[4].category1Fee){
                return (
                  <span>
                    {row.yearFeeList[4].category1Fee.paidFee / 100}
                  </span>
                );
              }
            }
          },
          {
            title: '欠费',
            field: 'street',
            key: 'street23',
            width: 100,
            renderBodyCell: ({ row, column, rowIndex }, h) => {
              if(row.yearFeeList && row.yearFeeList.length >= 5 && row.yearFeeList[4].category1Fee){
                return (
                  <span>
                    {row.yearFeeList[4].category1Fee.owedFee / 100}
                  </span>
                );
              }
            }
          },
        ],
      },
      {
        title: '保险',
        children: [
          {
            title: '应缴',
            field: 'street',
            key: 'street111',
            width: 100,
            renderBodyCell: ({ row, column, rowIndex }, h) => {
              if(row.yearFeeList && row.yearFeeList.length >= 5 && row.yearFeeList[4].category2Fee){
                return (
                  <span>
                    {row.yearFeeList[4].category2Fee.totalFee / 100}
                  </span>
                );
              }
            }
          },
          {
            title: '实缴',
            field: 'street',
            key: 'street112',
            width: 100,
            renderBodyCell: ({ row, column, rowIndex }, h) => {
              if(row.yearFeeList && row.yearFeeList.length >= 5 && row.yearFeeList[4].category2Fee){
                return (
                  <span>
                    {row.yearFeeList[4].category2Fee.paidFee / 100}
                  </span>
                );
              }
            }
          },
          {
            title: '欠费',
            field: 'street',
            key: 'street113',
            width: 100,
            renderBodyCell: ({ row, column, rowIndex }, h) => {
              if(row.yearFeeList && row.yearFeeList.length >= 5 && row.yearFeeList[4].category2Fee){
                return (
                  <span>
                    {row.yearFeeList[4].category2Fee.owedFee / 100}
                  </span>
                );
              }
            }
          },
        ],
      },
    ],
  },
  { title: '参谋长姓名', field: 'address', key: '7', width: 150 },
  { title: '参谋长手机号', field: 'address', key: '8', width: 150 },
  // {
  //   title: '操作',
  //   key: 'operation',
  //   fixed: 'right',
  //   width: 100,
  //   renderBodyCell: ({ row, column, rowIndex }, h) => {
  //     return (
  //       <a>
  //         action
  //       </a>
  //     );
  //   },
  // },
];

const beginYear = 2019

export default {
  name: 'StudentStatic',
  data() {
    return {
      loading: false,
      page: 1,
      total: 0,
      gradeList: [],
      classList: [],

      data: [],
      columns: columns2,
      columnWidthResizeOption: {
          // default false
          enable: true,
          // column resize min width
          minWidth: 30,
          // column size change
          sizeChange: ({ column, differWidth, columnWidth }) => {
          },
      },
      params: {
        jwClazzIdList: [],
        year: 2023,
        jwStatus: undefined,
        owedFee: undefined,
      },
    };
  },
  watch: {
    'params.jwClazzIdList': function(val) {
      this.getDate(1)
    }
  },
  async mounted() {
    this.loading = true
    let currentYear = new Date().getFullYear()
    let gradeList = []
    for(let i = beginYear; i <= currentYear; i++) {
      gradeList.push(i)
    }
    this.gradeList = gradeList
    this.params.grade = currentYear

    this.classList = await clazzApi.currentClassList()
    this.loading = false
  },
  methods: {
    async getDate(page) {
      let resp = await feeApi.chargeTeacherStat(page, {
        jwClazzIdList: this.params.jwClazzIdList,
        jwStatus: this.params.jwStatus,
        owedFee: this.params.owedFee
      })
      this.data = resp.list
      this.page = page
      this.total = resp.total
    }
  }
}
</script>
