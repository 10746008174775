module.exports = {
    genGradeListDict: function() {
        let gradeListDict = []
        let currentYear = new Date().getFullYear()
        for(let i = 0; i < 5; i++) {
          gradeListDict.push(currentYear - i)
        }
        return gradeListDict
      },
      genFeeDategoryListDict: function() {
        let data = [{
            id: 1,
            label: '学宿代'
        },{
            id: 2,
            label: '保险'
        },{
            id: 3,
            label: '服装费'
        },{
            id: 4,
            label: '服装费补差'
        },{
            id: 5,
            label: '床上用品'
        },{
            id: 6,
            label: '体检费'
        }]
        return data
    },
    genBillYear: function() {
        let yearList = []
        let startYear = 2019
        let currentYear = new Date().getFullYear()
        for(let i = startYear; i <= currentYear; i++) {
            yearList.push(i)
        }
        return yearList
    }
}