<template>
  <div class="home" style="margin-top: 16px;margin-bottom: 16px;">
    <a-form-model layout="inline">
      <a-row>
        <a-col :span="4">
          <a-form-model-item label="所在年级">
            <a-select style="width: 160px" v-model="params.grade">
              <a-select-option :value="year" v-for="year in gradeList" :key="year">
                {{year}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="4">
          <a-form-model-item label="院校">
            <a-select style="width: 160px" v-model="params.companyId">
              <a-select-option :value="school.id" v-for="school in schoolList" :key="school.id">
                {{school.name}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="4">
          <a-form-model-item label="分院">
            <a-select style="width: 160px" v-model="params.collegeId">
              <a-select-option :value="college.id" v-for="college in collegeList" :key="college.id">
                {{college.name}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="4">
          <a-form-model-item label="专业">
            <a-select style="width: 160px" v-model="params.majorId">
              <a-select-option :value="major.id" v-for="major in majorList" :key="major.id">
                {{major.name}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="4">
          <a-form-model-item label="班级">
            <a-select style="width: 160px" v-model="params.classId">
              <a-select-option :value="clazz.id" v-for="clazz in classList" :key="clazz.id">
                {{clazz.name}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="4">
          <a-form-model-item label="姓名">
            <a-input v-model="params.name" placeholder="学生姓名" />
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="4" :offset="20">
          <a-button type="primary">查询</a-button>
        </a-col>
      </a-row>
    </a-form-model>
    <a-spin :spinning="loading">
      <ve-table :columns="columns" 
        style="margin-top: 8px;"
        :table-data="data" 
        :scroll-width="1500" 
        max-height="calc(100vh - 112px)" 
        :fixed-header="true"
        :border-around="true"
        :border-x="true"
        :border-y="true"
        :column-width-resize-option="columnWidthResizeOption"/>
    </a-spin>
  </div>
</template>

<script>

const columns2 = [
  { title: '姓名', width: 140, field: 'name', key: 'name', fixed: 'left', align: "left", },
  { title: '身份证号', width: 180, field: 'idNumber', key: 'idNumber', fixed: 'left' },
  { title: '性别', width: 100, field: 'gander', key: 'age9991', fixed: 'left' },
  { title: '手机号', width: 120, field: 'mobile', key: 'age9992'},
  { title: '所在年级', width: 100, field: 'grade', key: 'age9993'},
  { title: '培养层次', width: 100, field: 'batchName1', key: 'age99914'},
  { title: '学制', width: 100, field: 'batchName2', key: 'age9994'},
  { title: '分院', field: 'collegeName', key: '4', width: 150 },
  { title: '专业', field: 'majorName', key: '5', width: 150 },
  { title: '当前班级', field: 'className', key: '6', width: 150 },
  { title: '累计欠费', field: 'arrears', width: 100, key: 'age9995', 
    renderBodyCell: ({ row, column, rowIndex }, h) => {
      if(row.arrears > 0) {
        return (
          <span style="color: red;">
            {row.arrears}
          </span>
        );
      }else{
        return (
          <span>
            {row.arrears}
          </span>
        );
      }
    }
  },
  {
    title: '第一学年',
    children: [
      {
        title: '缴费时间',
        field: 'prePayTime',
        key: 'age20',
        width: 100,
      },
      {
        title: '学宿代',
        children: [
          {
            title: '应缴',
            field: 'main1',
            key: 'street201',
            width: 100,
          },
          {
            title: '实缴',
            field: 'main2',
            key: 'street202',
            width: 100,
          },
          {
            title: '欠费',
            field: 'main3',
            key: 'street203',
            width: 100,
            renderBodyCell: ({ row, column, rowIndex }, h) => {
              if(row.main3 > 0) {
                return (
                  <span style="color: red;">
                    {row.main3}
                  </span>
                );
              }else{
                return (
                  <span>
                    {row.main3}
                  </span>
                );
              }
            }
          },
        ],
      },
      {
        title: '保险',
        children: [
          {
            title: '应缴',
            field: 'street',
            key: 'street1911',
            width: 100,
          },
          {
            title: '实缴',
            field: 'street',
            key: 'street1912',
            width: 100,
          },
          {
            title: '欠费',
            field: 'street',
            key: 'street1913',
            width: 100,
          },
        ],
      },
      {
        title: '服装费',
        children: [
          {
            title: '应缴',
            field: 'street',
            key: 'street1821',
            width: 100,
          },
          {
            title: '实缴',
            field: 'street',
            key: 'street1822',
            width: 100,
          },
          {
            title: '欠费',
            field: 'street',
            key: 'street1823',
            width: 100,
          },
        ],
      },
      {
        title: '服装费补差',
        children: [
          {
            title: '应缴',
            field: 'street',
            key: 'street1731',
            width: 100,
          },
          {
            title: '实缴',
            field: 'street',
            key: 'street1732',
            width: 100,
          },
          {
            title: '欠费',
            field: 'street',
            key: 'street1733',
            width: 100,
          },
        ],
      },
      {
        title: '床上用品',
        children: [
          {
            title: '应缴',
            field: 'street',
            key: 'street1641',
            width: 100,
          },
          {
            title: '实缴',
            field: 'street',
            key: 'street1642',
            width: 100,
          },
          {
            title: '欠费',
            field: 'street',
            key: 'street1643',
            width: 100,
          },
        ],
      },
      {
        title: '体检费',
        children: [
          {
            title: '应缴',
            field: 'street',
            key: 'street1551',
            width: 100,
          },
          {
            title: '实缴',
            field: 'street',
            key: 'street1552',
            width: 100,
          },
          {
            title: '欠费',
            field: 'street',
            key: 'street1553',
            width: 100,
          },
        ],
      },
    ],
  },
  // {
  //   title: '第一学年',
  //   children: [
  //     {
  //       title: '缴费时间',
  //       field: 'age',
  //       key: 'age14',
  //       width: 100,
  //     },
  //     {
  //       title: '学宿代',
  //       children: [
  //         {
  //           title: '应缴',
  //           field: 'street',
  //           key: 'street141',
  //           width: 100,
  //         },
  //         {
  //           title: '实缴',
  //           field: 'street',
  //           key: 'street142',
  //           width: 100,
  //         },
  //         {
  //           title: '欠费',
  //           field: 'street',
  //           key: 'street143',
  //           width: 100,
  //         },
  //       ],
  //     },
  //     {
  //       title: '保险',
  //       children: [
  //         {
  //           title: '应缴',
  //           field: 'street',
  //           key: 'street1311',
  //           width: 100,
  //         },
  //         {
  //           title: '实缴',
  //           field: 'street',
  //           key: 'street1312',
  //           width: 100,
  //         },
  //         {
  //           title: '欠费',
  //           field: 'street',
  //           key: 'street1313',
  //           width: 100,
  //         },
  //       ],
  //     },
  //     {
  //       title: '服装费',
  //       children: [
  //         {
  //           title: '应缴',
  //           field: 'street',
  //           key: 'street1221',
  //           width: 100,
  //         },
  //         {
  //           title: '实缴',
  //           field: 'street',
  //           key: 'street1222',
  //           width: 100,
  //         },
  //         {
  //           title: '欠费',
  //           field: 'street',
  //           key: 'street1223',
  //           width: 100,
  //         },
  //       ],
  //     },
  //     {
  //       title: '服装费补差',
  //       children: [
  //         {
  //           title: '应缴',
  //           field: 'street',
  //           key: 'street1131',
  //           width: 100,
  //         },
  //         {
  //           title: '实缴',
  //           field: 'street',
  //           key: 'street1132',
  //           width: 100,
  //         },
  //         {
  //           title: '欠费',
  //           field: 'street',
  //           key: 'street1133',
  //           width: 100,
  //         },
  //       ],
  //     },
  //     {
  //       title: '床上用品',
  //       children: [
  //         {
  //           title: '应缴',
  //           field: 'street',
  //           key: 'street1041',
  //           width: 100,
  //         },
  //         {
  //           title: '实缴',
  //           field: 'street',
  //           key: 'street1042',
  //           width: 100,
  //         },
  //         {
  //           title: '欠费',
  //           field: 'street',
  //           key: 'street1043',
  //           width: 100,
  //         },
  //       ],
  //     },
  //     {
  //       title: '体检费',
  //       children: [
  //         {
  //           title: '应缴',
  //           field: 'street',
  //           key: 'street951',
  //           width: 100,
  //         },
  //         {
  //           title: '实缴',
  //           field: 'street',
  //           key: 'street952',
  //           width: 100,
  //         },
  //         {
  //           title: '欠费',
  //           field: 'street',
  //           key: 'street953',
  //           width: 100,
  //         },
  //       ],
  //     },
  //   ],
  // },
  {
    title: '第二学年',
    children: [
      {
        title: '缴费时间',
        field: 'age',
        key: 'age8',
        width: 100,
      },
      {
        title: '学宿代',
        children: [
          {
            title: '应缴',
            field: 'street',
            key: 'street81',
            width: 100,
          },
          {
            title: '实缴',
            field: 'street',
            key: 'street82',
            width: 100,
          },
          {
            title: '欠费',
            field: 'street',
            key: 'street83',
            width: 100,
          },
        ],
      },
      {
        title: '保险',
        children: [
          {
            title: '应缴',
            field: 'street',
            key: 'street711',
            width: 100,
          },
          {
            title: '实缴',
            field: 'street',
            key: 'street712',
            width: 100,
          },
          {
            title: '欠费',
            field: 'street',
            key: 'street713',
            width: 100,
          },
        ],
      },
    ],
  },
  {
    title: '第三学年',
    children: [
      {
        title: '缴费时间',
        field: 'age',
        key: 'age6',
        width: 100,
      },
      {
        title: '学宿代',
        children: [
          {
            title: '应缴',
            field: 'street',
            key: 'street61',
            width: 100,
          },
          {
            title: '实缴',
            field: 'street',
            key: 'street62',
            width: 100,
          },
          {
            title: '欠费',
            field: 'street',
            key: 'street63',
            width: 100,
          },
        ],
      },
      {
        title: '保险',
        children: [
          {
            title: '应缴',
            field: 'street',
            key: 'street511',
            width: 100,
          },
          {
            title: '实缴',
            field: 'street',
            key: 'street512',
            width: 100,
          },
          {
            title: '欠费',
            field: 'street',
            key: 'street513',
            width: 100,
          },
        ],
      },
    ],
  },
  {
    title: '第四学年',
    children: [
      {
        title: '缴费时间',
        field: 'age',
        key: 'age4',
        width: 100,
      },
      {
        title: '学宿代',
        children: [
          {
            title: '应缴',
            field: 'street',
            key: 'street41',
            width: 100,
          },
          {
            title: '实缴',
            field: 'street',
            key: 'street42',
            width: 100,
          },
          {
            title: '欠费',
            field: 'street',
            key: 'street43',
            width: 100,
          },
        ],
      },
      {
        title: '保险',
        children: [
          {
            title: '应缴',
            field: 'street',
            key: 'street311',
            width: 100,
          },
          {
            title: '实缴',
            field: 'street',
            key: 'street312',
            width: 100,
          },
          {
            title: '欠费',
            field: 'street',
            key: 'street313',
            width: 100,
          },
        ],
      },
    ],
  },
  {
    title: '第五学年',
    children: [
      {
        title: '缴费时间',
        field: 'age',
        key: 'age2263',
        width: 100,
      },
      {
        title: '学宿代',
        children: [
          {
            title: '应缴',
            field: 'street',
            key: 'street21',
            width: 100,
          },
          {
            title: '实缴',
            field: 'street',
            key: 'street22',
            width: 100,
          },
          {
            title: '欠费',
            field: 'street',
            key: 'street23',
            width: 100,
          },
        ],
      },
      {
        title: '保险',
        children: [
          {
            title: '应缴',
            field: 'street',
            key: 'street111',
            width: 100,
          },
          {
            title: '实缴',
            field: 'street',
            key: 'street112',
            width: 100,
          },
          {
            title: '欠费',
            field: 'street',
            key: 'street113',
            width: 100,
          },
        ],
      },
    ],
  },
  { title: '参谋长姓名', field: 'address', key: '7', width: 150 },
  { title: '参谋长手机号', field: 'address', key: '8', width: 150 },
  // {
  //   title: '操作',
  //   key: 'operation',
  //   fixed: 'right',
  //   width: 100,
  //   renderBodyCell: ({ row, column, rowIndex }, h) => {
  //     return (
  //       <a>
  //         action
  //       </a>
  //     );
  //   },
  // },
];

const data = [];
for (let i = 0; i < 100; i++) {
  let arrears = 0
  let main1 = 16600 + 1200 + 700
  let main2 = 16600 + 1200 + 700
  if(i % 3 == 0){
    arrears = Math.ceil(Math.random() * 1000)
    main2 = main2 - arrears
  }
  data.push({
    key: i,
    name: `张三 ${i}`,
    idNumber: '510104199009090099',
    gander: '男',
    mobile: '13666666666',
    grade: '2023',
    batchName: '',
    collegeName: '空中乘务学院',
    majorName: '空中乘务',
    className: '空乘1班',
    arrears: arrears,
    prePayTime: '2023-04-17',
    main1: main1,
    main2: main2,
    main3: arrears,
    age: '2023-04-17',
    batchName1: '三年制',
    batchName2: '3年',
  });
}

const beginYear = 2019

export default {
  name: 'HomeView',
  data() {
    return {
      data,
      columns: columns2,
      columnWidthResizeOption: {
          // default false
          enable: true,
          // column resize min width
          minWidth: 30,
          // column size change
          sizeChange: ({ column, differWidth, columnWidth }) => {
          },
      },
      loading: false,
      gradeList: [],
      schoolList: [{
        id: 1,
        name: '四川西南航空职业学院'
      }],
      collegeList: [{
        id: 1,
        name: '空中乘务学院'
      }],
      majorList: [{
        id: 1,
        name: '空中乘务'
      }],
      classList: [{
        id: 1,
        name: '空乘1班'
      }],
      params: {
        grade: 2023,
        companyId: 1,
        collegeId: undefined,
        majorId: undefined,
        classId: undefined
      },
    };
  },
  mounted() {
    this.loading = true
    let currentYear = new Date().getFullYear()
    let gradeList = []
    for(let i = beginYear; i <= currentYear; i++) {
      gradeList.push(i)
    }
    this.gradeList = gradeList
    this.params.grade = currentYear
    this.loading = false
  }
}
</script>
