<template>
  <div>
    <van-nav-bar
      title="班级欠费"
      left-text="返回"
      left-arrow
      :fixed="true"
      :placeholder="true"
      @click-left="() => {$router.back()}"/>
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad">
      <van-cell-group>
        <van-cell :clickable="true" :to="{name: 'clazzStudentList', params: {clazzId: clazz.id}, query: {name: clazz.name}}" v-for="clazz in clazzList" :key="clazz.id" :title="clazz.name" :label="clazz.code" :value="`班主任：${clazz.bzrxm}`"/>
      </van-cell-group>
    </van-list>
  </div>
</template>
  
<script>
const clone = require('rfdc')()
import ClazzApi from '@/api/src/clazz/api/ClazzApi'
const clazzApi = new ClazzApi()

export default {
  name: 'ClazzList',
  data() {
    return {
      loading: false,
      finished: false,
      clazzList: [],
    }
  },
  async mounted() {
    
  },
  methods: {
    async onLoad() {
      this.loading = true;
      await this.getDate(this.page + 1)
      this.finished = true;
      this.loading = false;
    },
    async getDate() {
      this.clazzList = await clazzApi.currentClassList()
    },
    // goClazzStat(clazz) {
    //   console.log(clazz)
    //   this.$router.push({name: 'clazzStaticMobile', params: {clazzId: clazz.id}, query: {name: clazz.name}})
    // },
  }
}
</script>
  
<style>

</style>