<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  computed: mapState({
    companyUser: state => state.security.companyUser,
  }),
  created() {
    console.log(this.companyUser)
    console.log(this.$route)
    if(this.$route.name != 'login' && (!this.companyUser || !this.companyUser.company)) {
      this.$router.replace({name: 'login'})
    }
  }
}
</script>

<style lang="less">
</style>
