<template>
  <div>
    <van-nav-bar 
      title="缴费信息"
      left-text="返回"
      left-arrow
      :fixed="true"
      :placeholder="true"
      @click-left="() => {$router.back()}"/>
    <a-row>
      <van-cell-group inset>
        <van-cell :title="student.realname">
          <template #label>
            <van-row>
              <van-col span="8">学号</van-col>
              <van-col span="16">{{ student.code }}</van-col>
            </van-row>
            <van-row>
              <van-col span="8">身份证件号</van-col>
              <van-col span="16">{{ student.idNumber }}</van-col>
            </van-row>
            <van-row>
              <van-col span="8">性别</van-col>
              <van-col span="16" v-if="student.gender == 1">男</van-col>
              <van-col span="16" v-if="student.gender == 2">女</van-col>
            </van-row>
            <van-row>
              <van-col span="8">专业</van-col>
              <van-col span="16">{{ student.jwMajor.name }}</van-col>
            </van-row>
            <van-row>
              <van-col span="8">分院</van-col>
              <van-col span="16">{{ student.jwDept.name }}</van-col>
            </van-row>
            <van-row>
              <van-col span="8">班级</van-col>
              <van-col span="16">{{ student.jwClazz.name }}</van-col>
            </van-row>
          </template>
          <template #right-icon>
            <span style="color: red" v-if="student.sumFee && student.sumFee.owedFee > 0">欠费金额：{{ student.sumFee.owedFee }}</span>
            <span v-else style="color: #969799">无欠费</span>
          </template>
       </van-cell>
      </van-cell-group>
      <van-tabs v-model="active" sticky scrollspy>
        <van-tab title="合计">
          <van-row style="margin: 0 16px;">
            <van-row style="margin-top: 16px;">
              <van-tag type="primary">合计</van-tag>
            </van-row>
            <StickerTable
            v-if="student.code"
            :columns="columns"
            :immediate="true"
            :loadData="loadDataTotal"/>
          </van-row>
        </van-tab>
        <van-tab title="第一年">
          <van-row style="margin: 0 16px;">
            <van-row style="margin-top: 16px;">
              <van-tag type="primary">第一学年</van-tag>
            </van-row>
            <StickerTable
            v-if="student.code"
            :columns="columns"
            :immediate="true"
            :loadData="() => {return loadData(0)}"/>
          </van-row>
        </van-tab>
        <van-tab title="第二年">
          <van-row style="margin: 0 16px;">
            <van-row style="margin-top: 16px;">
              <van-tag type="primary">第二学年</van-tag>
            </van-row>
            <StickerTable
            v-if="student.code"
            :columns="columns"
            :immediate="true"
            :loadData="() => {return loadData(1)}"/>
          </van-row>
        </van-tab>
        <van-tab title="第三年">
          <van-row style="margin: 0 16px;">
            <van-row style="margin-top: 16px;">
              <van-tag type="primary">第三学年</van-tag>
            </van-row>
            <StickerTable
            v-if="student.code"
            :columns="columns"
            :immediate="true"
            :loadData="() => {return loadData(2)}"/>
          </van-row>
        </van-tab>
        <van-tab title="第四年">
          <van-row style="margin: 0 16px;">
            <van-row style="margin-top: 16px;">
              <van-tag type="primary">第四学年</van-tag>
            </van-row>
            <StickerTable
            v-if="student.code"
            :columns="columns"
            :immediate="true"
            :loadData="() => {return loadData(3)}"/>
          </van-row>
        </van-tab>
        <van-tab title="第五年">
          <van-row style="margin: 0 16px;">
            <van-row style="margin-top: 16px;">
              <van-tag type="primary">第五学年</van-tag>
            </van-row>
            <StickerTable
            v-if="student.code"
            :columns="columns"
            :immediate="true"
            :loadData="() => {return loadData(4)}"/>
          </van-row>
        </van-tab>
      </van-tabs>
    </a-row>
  </div>
</template>
  
<script>
import StickerTable from 'vue-sticker-table'
const columns = [
  {
    title: '项目',
    dataIndex: 'cateName',
    // width: 55
  },
  {
    title: '应缴',
    dataIndex: 'totalFee',
    // width: 130
  },
  {
    title: '实缴',
    dataIndex: 'paidFee',
    ellipsis: true,
    // width: 150
  },
  {
    title: '欠费',
    dataIndex: 'owedFee',
    // width: 95
  },
]
const yearConfigsList = [[{
  index: 1,
  name: '学宿带'
},{
  index: 2,
  name: '保险'
},{
  index: 3,
  name: '服装费'
},{
  index: 4,
  name: '服装费补差'
},{
  index: 5,
  name: '床上用品'
},{
  index: 6,
  name: '体检费'
}],[{
  index: 1,
  name: '学宿带'
},{
  index: 2,
  name: '保险'
}],[{
  index: 1,
  name: '学宿带'
},{
  index: 2,
  name: '保险'
}],[{
  index: 1,
  name: '学宿带'
},{
  index: 2,
  name: '保险'
}],[{
  index: 1,
  name: '学宿带'
},{
  index: 2,
  name: '保险'
}]]
export default {
  name: 'ClazzStudentStatic',
  components: {
    StickerTable
  },
  data() {
    return {
      immediate: true,
      columns: columns,
      student: {},
      active: 0,
      code: undefined
    }
  },
  mounted() {
    this.student = this.$route.params
    console.log(this.student)
  },
  methods: {
    loadDataTotal() {
      let data = []
      if(this.student.sumFee) {
        let yearConfigs = yearConfigsList[0]
        for(let i = 0; i < yearConfigs.length; i++) {
          data.push({
            cateName: yearConfigs[i].name,
            totalFee: this.student.sumFee[`category${yearConfigs[i].index}Fee`].totalFee / 100,
            paidFee: this.student.sumFee[`category${yearConfigs[i].index}Fee`].paidFee / 100,
            owedFee: this.student.sumFee[`category${yearConfigs[i].index}Fee`].owedFee / 100
          })
        }
      }
      return data
    },
    loadData(index) {
      let data = []
      if(this.student.yearFeeList.length >= index + 1){
        let yearConfigs = yearConfigsList[index]
        for(let i = 0; i < yearConfigs.length; i++) {
          data.push({
            cateName: yearConfigs[i].name,
            totalFee: this.student.yearFeeList[index][`category${yearConfigs[i].index}Fee`].totalFee / 100,
            paidFee: this.student.yearFeeList[index][`category${yearConfigs[i].index}Fee`].paidFee / 100,
            owedFee: this.student.yearFeeList[index][`category${yearConfigs[i].index}Fee`].owedFee / 100
          })
        }
      }
      return data
    }
  }
}
</script>
  
<style>

</style>