/**
 * 用户接口
 * 用户接口API
 *
 * OpenAPI spec version: 0.2.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import ApiClient from '../../ApiClient';
import CompleteInfoForm from '../../token/model/CompleteInfoForm';
import DingCodeForm from '../../token/model/DingCodeForm';
import LoginTokenVo from '../../token/model/LoginTokenVo';
import PwdLoginForm from '../../token/model/PwdLoginForm';
import TokenRefreshForm from '../../token/model/TokenRefreshForm';
  /**
   * Token service.
   * @module token/api/TokenApi
   * @version 0.2.0
   */

  /**
   * Constructs a new TokenApi. 
   * @alias module:token/api/TokenApi
   * @class
   * @param {module:ApiClient} apiClient Optional API client implementation to use,
   * default to {@link module:ApiClient#instance} if unspecified.
   */
  var exports = function(apiClient) {
    this.apiClient = apiClient || ApiClient.instance;



    /**
     * 钉钉信息不完善时，手动补全信息
     * @param {module:token/model/CompleteInfoForm} body 请求参数body
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:token/model/LoginTokenVo}
     */
this.completeInfo = function(body) {
      var postBody = body;

      // verify the required parameter 'body' is set
      if (body == undefined || body == null) {
        throw "Missing the required parameter 'body' when calling completeInfo";
      }


      var pathParams = {
      };
      var queryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var isPublic = false;

      var authNames = [];
      var contentTypes = [];
      var accepts = [];
      var returnType = LoginTokenVo;
      return this.apiClient.callApi(
        '/api/center/token/ding/complete_info', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType,isPublic
      );
    }


    /**
     * 钉钉H5换取token
     * @param {module:token/model/DingCodeForm} body 请求参数body
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:token/model/LoginTokenVo}
     */
this.getTokenByH5DingCode = function(body) {
      var postBody = body;

      // verify the required parameter 'body' is set
      if (body == undefined || body == null) {
        throw "Missing the required parameter 'body' when calling getTokenByH5DingCode";
      }


      var pathParams = {
      };
      var queryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var isPublic = false;

      var authNames = [];
      var contentTypes = [];
      var accepts = [];
      var returnType = LoginTokenVo;
      return this.apiClient.callApi(
        '/api/center/token/ding/h5', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType,isPublic
      );
    }


    /**
     * 钉钉移动客户端登录换取token
     * @param {module:token/model/DingCodeForm} body 请求参数body
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:token/model/LoginTokenVo}
     */
this.getTokenByNativeDingCode = function(body) {
      var postBody = body;

      // verify the required parameter 'body' is set
      if (body == undefined || body == null) {
        throw "Missing the required parameter 'body' when calling getTokenByNativeDingCode";
      }


      var pathParams = {
      };
      var queryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var isPublic = false;

      var authNames = [];
      var contentTypes = [];
      var accepts = [];
      var returnType = LoginTokenVo;
      return this.apiClient.callApi(
        '/api/center/token/ding/native', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType,isPublic
      );
    }


    /**
     * 手机号密码换取token
     * @param {module:token/model/PwdLoginForm} body 请求参数body
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:token/model/LoginTokenVo}
     */
this.getTokenByPwd = function(body) {
      var postBody = body;

      // verify the required parameter 'body' is set
      if (body == undefined || body == null) {
        throw "Missing the required parameter 'body' when calling getTokenByPwd";
      }


      var pathParams = {
      };
      var queryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var isPublic = false;

      var authNames = [];
      var contentTypes = [];
      var accepts = [];
      var returnType = LoginTokenVo;
      return this.apiClient.callApi(
        '/api/center/token/pwd', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType,isPublic
      );
    }


    /**
     * 刷新token
     * @param {module:token/model/TokenRefreshForm} body 请求参数body
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:token/model/LoginTokenVo}
     */
this.refreshToken = function(body) {
      var postBody = body;

      // verify the required parameter 'body' is set
      if (body == undefined || body == null) {
        throw "Missing the required parameter 'body' when calling refreshToken";
      }


      var pathParams = {
      };
      var queryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var isPublic = false;

      var authNames = [];
      var contentTypes = [];
      var accepts = [];
      var returnType = LoginTokenVo;
      return this.apiClient.callApi(
        '/api/center/token/refresh_token', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType,isPublic
      );
    }
  };

  export default exports;
