import Vue from 'vue'
import VueRouter from 'vue-router'
import FeeStatic from '@/views/FeeStatic.vue'
import Login from '@/views/Login.vue'
import Home from '@/views/Home.vue'
import StudentStatic from '@/views/StudentStatic.vue'
import ClazzList from '@/views/ClazzList.vue'
import ClazzStudentList from '@/views/ClazzStudentList.vue'
import ClazzStudentStatic from '@/views/ClazzStudentStatic.vue'
import DeptStatic from '@/views/DeptStatic.vue'
import StudentList from '@/views/StudentList.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: Login
  },
  {
    path: '/home',
    name: 'home',
    component: Home
  },

  {
    path: '/clazz_list',
    name: 'clazzList',
    component: ClazzList
  },
  {
    path: '/clazz_student_list/:clazzId',
    name: 'clazzStudentList',
    component: ClazzStudentList
  },
  {
    path: '/clazz_student_static',
    name: 'clazzStudentStatic',
    component: ClazzStudentStatic
  },
  {
    path: '/dept_static',
    name: 'deptStatic',
    component: DeptStatic
  },
  {
    path: '/student_list',
    name: 'studentList',
    component: StudentList
  },
  {
    path: '/student_static',
    name: 'studentStatic',
    component: StudentStatic
  },
  {
    path: '/fee_static',
    name: 'feeStatic',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: FeeStatic
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
