/**
 * 用户接口
 * 用户接口API
 *
 * OpenAPI spec version: 0.2.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import ApiClient from '../../ApiClient';
import CompanyLoginForm from '../../account/model/CompanyLoginForm';
import CompanyLoginSuccessVo from '../../account/model/CompanyLoginSuccessVo';
import UpdatedVo from '../../account/model/UpdatedVo';
  /**
   * FeeAccount service.
   * @module account/api/FeeAccountApi
   * @version 0.2.0
   */

  /**
   * Constructs a new FeeAccountApi. 
   * @alias module:account/api/FeeAccountApi
   * @class
   * @param {module:ApiClient} apiClient Optional API client implementation to use,
   * default to {@link module:ApiClient#instance} if unspecified.
   */
  var exports = function(apiClient) {
    this.apiClient = apiClient || ApiClient.instance;



    /**
     * 获取当前用户信息
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:account/model/CompanyLoginSuccessVo}
     */
this.current = function() {
      var postBody = null;


      var pathParams = {
      };
      var queryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var isPublic = false;

      var authNames = [];
      var contentTypes = [];
      var accepts = [];
      var returnType = CompanyLoginSuccessVo;
      return this.apiClient.callApi(
        '/api/account/fee/current', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType,isPublic
      );
    }


    /**
     * 选择登录的公司
     * @param {module:account/model/CompanyLoginForm} body 请求参数body
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:account/model/CompanyLoginSuccessVo}
     */
this.loginCompany = function(body) {
      var postBody = body;

      // verify the required parameter 'body' is set
      if (body == undefined || body == null) {
        throw "Missing the required parameter 'body' when calling loginCompany";
      }


      var pathParams = {
      };
      var queryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var isPublic = false;

      var authNames = [];
      var contentTypes = [];
      var accepts = [];
      var returnType = CompanyLoginSuccessVo;
      return this.apiClient.callApi(
        '/api/account/fee/login_company', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType,isPublic
      );
    }


    /**
     * 用户登出
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:account/model/UpdatedVo}
     */
this.logout = function() {
      var postBody = null;


      var pathParams = {
      };
      var queryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var isPublic = false;

      var authNames = [];
      var contentTypes = [];
      var accepts = [];
      var returnType = UpdatedVo;
      return this.apiClient.callApi(
        '/api/account/fee/logout', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType,isPublic
      );
    }
  };

  export default exports;
