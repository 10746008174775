<template>
  <div>
    <van-nav-bar 
      title="缴费进度管理"
      :fixed="true"
      :placeholder="true"/>
    <a-row>
      <van-grid :column-num="3">
        <van-grid-item @click="goTeacherStat" icon="photo-o" text="班级欠费" />
        <van-grid-item @click="goDeptStat" icon="photo-o" text="分院欠费" />
        <van-grid-item @click="goStudentList" icon="photo-o" text="学生欠费" />
        <!-- <van-grid-item icon="photo-o" text="文字" /> -->
      </van-grid>
    </a-row>
  </div>
</template>
  
<script>
  import * as dd from 'dingtalk-jsapi'
  export default {
    name: 'Home',
    data() {
      return {
        code: undefined
      }
    },
    mounted() {
      // dd.ready(() => {
      //   console.log('dd ready')
      //   // dd.ready参数为回调函数，在环境准备就绪时触发，jsapi的调用需要保证在该回调函数触发后调用，否则无效。
      //   dd.runtime.permission.requestAuthCode({
      //       corpId: "dinge4bc89cece3b9e2835c2f4657eb6378f",
      //       onSuccess: (result) => {
      //         console.log(result)
      //         this.code = result.code
      //       /*{
      //           code: 'hYLK98jkf0m' //string authCode
      //       }*/
      //       },
      //       onFail : (err) => {
      //         console.log(err)
      //       }
      
      //   });
      // });
    //   this.$router.push({name: 'feeStatic'})
    },
    methods: {
      goTeacherStat() {
        this.$router.push({name: 'clazzList'})
      },
      goDeptStat() {
        this.$router.push({name: 'deptStatic'})
      },
      goStudentList() {
        this.$router.push({name: 'studentList'})
      }
    }
  }
</script>
  
<style>

</style>