/**
 * 财务接口
 * 财务接口API
 *
 * OpenAPI spec version: 0.2.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import ApiClient from '../../ApiClient';
import FeeVo from './FeeVo';




  /**
   * The FeeStatVo model module.
   * @module bill/model/FeeStatVo
   * @version 0.2.0
   */

  /**
   * Constructs a new <code>FeeStatVo</code>.
   * @alias module:bill/model/FeeStatVo
   * @class
   */
  var exports = function() {
    var _this = this;















  };

  /**
   * Constructs a <code>FeeStatVo</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:bill/model/FeeStatVo} obj Optional instance to populate.
   * @return {module:bill/model/FeeStatVo} The populated <code>FeeStatVo</code> instance.
   */
  exports.constructFromObject = function(data, obj) {
    if (data) {
      obj = obj || new exports();

      if (Object.prototype.hasOwnProperty.call(data, 'id')) {
        obj['id'] = ApiClient.convertToType(data['id'], 'Integer');
      }
      if (Object.prototype.hasOwnProperty.call(data, 'studentNum')) {
        obj['studentNum'] = ApiClient.convertToType(data['studentNum'], 'Integer');
      }
      if (Object.prototype.hasOwnProperty.call(data, 'lastWeekOwnedFee')) {
        obj['lastWeekOwnedFee'] = ApiClient.convertToType(data['lastWeekOwnedFee'], 'Integer');
      }
      if (Object.prototype.hasOwnProperty.call(data, 'queryDateOwnedFee')) {
        obj['queryDateOwnedFee'] = ApiClient.convertToType(data['queryDateOwnedFee'], 'Integer');
      }
      if (Object.prototype.hasOwnProperty.call(data, 'year')) {
        obj['year'] = ApiClient.convertToType(data['year'], 'String');
      }
      if (Object.prototype.hasOwnProperty.call(data, 'name')) {
        obj['name'] = ApiClient.convertToType(data['name'], 'String');
      }
      if (Object.prototype.hasOwnProperty.call(data, 'totalFee')) {
        obj['totalFee'] = FeeVo.constructFromObject(data['totalFee']);
      }
      if (Object.prototype.hasOwnProperty.call(data, 'category1Fee')) {
        obj['category1Fee'] = FeeVo.constructFromObject(data['category1Fee']);
      }
      if (Object.prototype.hasOwnProperty.call(data, 'category2Fee')) {
        obj['category2Fee'] = FeeVo.constructFromObject(data['category2Fee']);
      }
      if (Object.prototype.hasOwnProperty.call(data, 'category3Fee')) {
        obj['category3Fee'] = FeeVo.constructFromObject(data['category3Fee']);
      }
      if (Object.prototype.hasOwnProperty.call(data, 'category4Fee')) {
        obj['category4Fee'] = FeeVo.constructFromObject(data['category4Fee']);
      }
      if (Object.prototype.hasOwnProperty.call(data, 'category5Fee')) {
        obj['category5Fee'] = FeeVo.constructFromObject(data['category5Fee']);
      }
      if (Object.prototype.hasOwnProperty.call(data, 'category6Fee')) {
        obj['category6Fee'] = FeeVo.constructFromObject(data['category6Fee']);
      }
      if (Object.prototype.hasOwnProperty.call(data, 'orderTime')) {
        obj['orderTime'] = ApiClient.convertToType(data['orderTime'], 'Date');
      }
    }
    return obj;
  }

  /**
   * 统计单位id，非实体表可以为空
   * @member {Integer} id
   */
  exports.prototype['id'] = undefined;
  /**
   * 人数
   * @member {Integer} studentNum
   */
  exports.prototype['studentNum'] = undefined;
  /**
   * 上周欠费
   * @member {Integer} lastWeekOwnedFee
   */
  exports.prototype['lastWeekOwnedFee'] = undefined;
  /**
   * 查询日期欠费
   * @member {Integer} queryDateOwnedFee
   */
  exports.prototype['queryDateOwnedFee'] = undefined;
  /**
   * 学年
   * @member {String} year
   */
  exports.prototype['year'] = undefined;
  /**
   * 统计单位名称/ 可以填合计或者费用学年
   * @member {String} name
   */
  exports.prototype['name'] = undefined;
  /**
   * 合计
   * @member {module:bill/model/FeeVo} totalFee
   */
  exports.prototype['totalFee'] = undefined;
  /**
   * 1：学宿代
   * @member {module:bill/model/FeeVo} category1Fee
   */
  exports.prototype['category1Fee'] = undefined;
  /**
   * 2：保险
   * @member {module:bill/model/FeeVo} category2Fee
   */
  exports.prototype['category2Fee'] = undefined;
  /**
   * 3：服装费欠费
   * @member {module:bill/model/FeeVo} category3Fee
   */
  exports.prototype['category3Fee'] = undefined;
  /**
   * 4：服装费补差
   * @member {module:bill/model/FeeVo} category4Fee
   */
  exports.prototype['category4Fee'] = undefined;
  /**
   * 5：床上用品
   * @member {module:bill/model/FeeVo} category5Fee
   */
  exports.prototype['category5Fee'] = undefined;
  /**
   * 6：体检费
   * @member {module:bill/model/FeeVo} category6Fee
   */
  exports.prototype['category6Fee'] = undefined;
  /**
   * 最后一笔缴费日期
   * @member {Date} orderTime
   */
  exports.prototype['orderTime'] = undefined;



  export default exports;
;


