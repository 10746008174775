/**
 * 财务接口
 * 财务接口API
 *
 * OpenAPI spec version: 0.2.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import ApiClient from '../../ApiClient';
import FeeStatVo from './FeeStatVo';
import IdNameVo from './IdNameVo';
import JwUserBaseVo from './JwUserBaseVo';




  /**
   * The StudentStatVo model module.
   * @module bill/model/StudentStatVo
   * @version 0.2.0
   */

  /**
   * Constructs a new <code>StudentStatVo</code>.
   * @alias module:bill/model/StudentStatVo
   * @class
   */
  var exports = function() {
    var _this = this;











  };

  /**
   * Constructs a <code>StudentStatVo</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:bill/model/StudentStatVo} obj Optional instance to populate.
   * @return {module:bill/model/StudentStatVo} The populated <code>StudentStatVo</code> instance.
   */
  exports.constructFromObject = function(data, obj) {
    if (data) {
      obj = obj || new exports();

      if (Object.prototype.hasOwnProperty.call(data, 'realname')) {
        obj['realname'] = ApiClient.convertToType(data['realname'], 'String');
      }
      if (Object.prototype.hasOwnProperty.call(data, 'gender')) {
        obj['gender'] = ApiClient.convertToType(data['gender'], 'Integer');
      }
      if (Object.prototype.hasOwnProperty.call(data, 'idNumber')) {
        obj['idNumber'] = ApiClient.convertToType(data['idNumber'], 'String');
      }
      if (Object.prototype.hasOwnProperty.call(data, 'code')) {
        obj['code'] = ApiClient.convertToType(data['code'], 'String');
      }
      if (Object.prototype.hasOwnProperty.call(data, 'jwDept')) {
        obj['jwDept'] = IdNameVo.constructFromObject(data['jwDept']);
      }
      if (Object.prototype.hasOwnProperty.call(data, 'jwMajor')) {
        obj['jwMajor'] = IdNameVo.constructFromObject(data['jwMajor']);
      }
      if (Object.prototype.hasOwnProperty.call(data, 'jwClazz')) {
        obj['jwClazz'] = IdNameVo.constructFromObject(data['jwClazz']);
      }
      if (Object.prototype.hasOwnProperty.call(data, 'teacherJwUser')) {
        obj['teacherJwUser'] = JwUserBaseVo.constructFromObject(data['teacherJwUser']);
      }
      if (Object.prototype.hasOwnProperty.call(data, 'sumFee')) {
        obj['sumFee'] = FeeStatVo.constructFromObject(data['sumFee']);
      }
      if (Object.prototype.hasOwnProperty.call(data, 'yearFeeList')) {
        obj['yearFeeList'] = ApiClient.convertToType(data['yearFeeList'], [FeeStatVo]);
      }
    }
    return obj;
  }

  /**
   * 【xm】姓名
   * @member {String} realname
   */
  exports.prototype['realname'] = undefined;
  /**
   * 【sex】性别 0：未知、1：男、2：女
   * @member {Integer} gender
   */
  exports.prototype['gender'] = undefined;
  /**
   * 【sfzjh】身份证件号
   * @member {String} idNumber
   */
  exports.prototype['idNumber'] = undefined;
  /**
   * 【xh】学号
   * @member {String} code
   */
  exports.prototype['code'] = undefined;
  /**
   * 分院
   * @member {module:bill/model/IdNameVo} jwDept
   */
  exports.prototype['jwDept'] = undefined;
  /**
   * 专业
   * @member {module:bill/model/IdNameVo} jwMajor
   */
  exports.prototype['jwMajor'] = undefined;
  /**
   * 班级
   * @member {module:bill/model/IdNameVo} jwClazz
   */
  exports.prototype['jwClazz'] = undefined;
  /**
   * 班级参谋长
   * @member {module:bill/model/JwUserBaseVo} teacherJwUser
   */
  exports.prototype['teacherJwUser'] = undefined;
  /**
   * 合计费用
   * @member {module:bill/model/FeeStatVo} sumFee
   */
  exports.prototype['sumFee'] = undefined;
  /**
   * 分学年费用
   * @member {Array.<module:bill/model/FeeStatVo>} yearFeeList
   */
  exports.prototype['yearFeeList'] = undefined;



  export default exports;
;


