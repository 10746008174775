<template>
  <div>
    <van-nav-bar
      title="分院欠费"
      left-text="返回"
      left-arrow
      :fixed="true"
      :placeholder="true"
      @click-left="() => {$router.back()}"/>
    <a-spin :spinning="loading">
      <van-field
        readonly
        clickable
        name="picker"
        :value="queryForm.schoolYear"
        label="账单年份"
        placeholder="点击选择账单年份"
        @click="showBillYearPicker = true"
      />
      <van-popup v-model="showBillYearPicker" position="bottom">
        <van-picker
          show-toolbar
          :columns="billYearColumns"
          @confirm="selectBillYear"
          @cancel="showBillYearPicker = false"
        />
      </van-popup>

      <van-field
        readonly
        clickable
        name="picker"
        :value="queryForm.gradeList.join(',')"
        label="年级"
        placeholder="点击选择年级"
        @click="showGradeModal = true"
      />
      <van-popup closeable v-model="showGradeModal" position="bottom">
        <van-checkbox-group v-model="queryForm.gradeList">
          <van-checkbox style="margin-top: 20px;margin-bottom: 20px;" v-for="grade in gradeListDict" :key="grade" :name="grade">{{grade}}</van-checkbox>
        </van-checkbox-group>
      </van-popup>

      <van-field
        readonly
        clickable
        name="picker"
        :value="feeDategoryStr"
        label="统计费用分类"
        placeholder="点击选择统计费用分类"
        @click="showFeeDategoryModal = true"
      />
      <van-popup closeable v-model="showFeeDategoryModal" position="bottom">
        <van-checkbox-group v-model="queryForm.feeDategoryList">
          <van-checkbox style="margin-top: 20px;margin-bottom: 20px;" v-for="feeDategory in feeDategoryListDict" :key="feeDategory.id" :name="feeDategory.id">{{feeDategory.label}}</van-checkbox>
        </van-checkbox-group>
      </van-popup>
      <!-- <van-cell title="参考日期" :value="date" @click="showDate = true" /> -->
      <van-calendar v-model="showDate" @confirm="selectDate" :min-date="new Date(2023, 10, 2)"/>
      <!-- <van-cell title="是否统计保险费" :value="insuranceFee" @click="showInsuranceFee = true"/> -->
      <!-- <van-popup v-model="showInsuranceFee" position="bottom" :style="{ height: '20%' }">
        <van-picker :columns="['否','是']" @confirm="changeInsuranceFee" confirm-button-text="确认" cancel-button-text="取消"/>
      </van-popup> -->
      <van-popup v-model="showInsuranceFee" position="bottom">
        <van-picker
          show-toolbar
          :columns="['否','是']"
          @cancel="showInsuranceFee = false"
          @confirm="changeInsuranceFee"
        />
      </van-popup>
      <van-button type="info" @click="getData" block>查询</van-button>
    </a-spin>
    <a-table style="margin-top: 20px;" :columns="columns" :data-source="data" :pagination="false" :loading="loading">
      <template slot="queryDate">
        <div>
          <span v-if="date" @click="showDate = true">参考{{ date }}欠费金额<a-icon type="search" style="margin-left: 10px;"/></span>
          <span v-else @click="showDate = true">无参考日期<a-icon type="search" style="margin-left: 10px;"/></span>
        </div>
      </template>
      <template slot="name" slot-scope="text, record">
        <a @click="() => goDeptDetail(record)">{{ record.name }}</a>
      </template>
      <template slot="category1OwedFee" slot-scope="text, record">
        <span v-if="record.category1Fee">{{ record.category1Fee.owedFee / 100 }}</span>
      </template>
      <template slot="category3OwedFee" slot-scope="text, record">
        <span v-if="record.category3Fee">{{ record.category3Fee.owedFee / 100 }}</span>
      </template>
      <template slot="category4OwedFee" slot-scope="text, record">
        <span v-if="record.category4Fee">{{ record.category4Fee.owedFee / 100 }}</span>
      </template>
      <template slot="category6OwedFee" slot-scope="text, record">
        <span v-if="record.category6Fee">{{ record.category6Fee.owedFee / 100 }}</span>
      </template>
      <template slot="totalFee" slot-scope="text, record">
        <span v-if="record.totalFee">{{ record.totalFee.owedFee / 100 }}</span>
      </template>
      <template slot="queryDateOwnedFee" slot-scope="text, record">
        <span v-if="record.totalFee">{{ record.queryDateOwnedFee / 100 }}</span>
      </template>
    </a-table>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { genGradeListDict, genFeeDategoryListDict, genBillYear } from '@/utils/constants.js'
import FeeApi from '@/api/src/bill/api/FeeApi'
import CommonApi from '@/api/src/common/api/CommonApi'
const feeApi = new FeeApi()
const commonApi = new CommonApi()
const columns = [
  {
    title: '分院',
    scopedSlots: { customRender: 'name' },
  },
  {
    title: '人数',
    dataIndex: 'studentNum',
    key: 'studentNum',
  },
  {
    title: '学费欠费金额',
    scopedSlots: { customRender: 'category1OwedFee' },
  },
  {
    title: '服装费欠费金额',
    scopedSlots: { customRender: 'category3OwedFee' },
  },
  {
    title: '服装费补差欠费金额',
    scopedSlots: { customRender: 'category4OwedFee' },
  },
  {
    title: '体检费欠费金额',
    scopedSlots: { customRender: 'category6OwedFee' },
  },
  {
    title: '总欠费金额',
    scopedSlots: { customRender: 'totalFee' },
  },
  {
    slots: { title: 'queryDate' },
    scopedSlots: { customRender: 'queryDateOwnedFee' },
  },
];

import {mapState} from "vuex";
export default {
  name: 'DeptStatic',
  data() {
    return {
      value: '',
      showPicker: false,
      columns: ['杭州', '宁波', '温州', '嘉兴', '湖州'],

      showBillYearPicker: false,
      billYearColumns: genBillYear(),

      loading: false,
      data: [],
      columns,
      showDate: false,
      date: undefined,
      showInsuranceFee: false,
      insuranceFee: '否',
      showGradeModal: false,
      gradeListDict: genGradeListDict(),
      showFeeDategoryModal: false,
      feeDategoryListDict: genFeeDategoryListDict(),
      feeDategoryStr: '',
      queryForm: {
        schoolYear: undefined,
        feeDategoryList: [],
        gradeList: [],
        queryDate: undefined,
        isInsuranceFee: false,
        companyId: 1
      }
    };
  },
  computed: mapState({
    companyUser: state => state.security.companyUser,
  }),
  watch: {
    'queryForm.feeDategoryList.length': function(val) {
      let feeDategoryLabelList = []
      for(let i = 0; i < this.queryForm.feeDategoryList.length; i++) {
        for(let j = 0; j < this.feeDategoryListDict.length; j++) {
          if(this.queryForm.feeDategoryList[i] == this.feeDategoryListDict[j].id){
            feeDategoryLabelList.push(this.feeDategoryListDict[j].label)
          }
        }
      }
      this.feeDategoryStr = feeDategoryLabelList.join(',')
    }
  },
  async mounted() {
    this.queryForm.gradeList = genGradeListDict()
    this.queryForm.feeDategoryList = [1,2,3,4,5,6]
    this.selectDate(dayjs().subtract(1, 'day').startOf('date').toDate())
  },
  methods: {
    selectBillYear(value) {
      this.queryForm.schoolYear = value;
      this.showBillYearPicker = false;
    },

    async selectDate(date) {
      this.showDate = false;
      console.log(date)
      this.date = dayjs(date).format('YYYY-MM-DD')
      this.queryForm.queryDate = date;
      await this.getData()
    },
    async changeInsuranceFee(value, index) {
      console.log(value, index)
      if(index == 0) {
        this.insuranceFee = '否'
        this.queryForm.isInsuranceFee = false
      }else if(index == 1) {
        this.insuranceFee = '是'
        this.queryForm.isInsuranceFee = true
      }
      this.showInsuranceFee = false;
    },
    async getData() {
      if(!this.queryForm.queryDate) {
        this.$toast.fail({
          message: "请选择参考日期",
          forbidClick: true,
          duration: 3000,
        });
        return
      }
      this.loading = true
      console.log(this.companyUser)
      try{
        let resp = await feeApi.deptStat(this.queryForm)
        console.log(resp)
        this.data = resp
      }catch(e) {
        this.$dialog.alert({
          message: e.message,
        }).then(() => {
          // on close
        });
      }finally{
        this.loading = false
      }
    },
    goDeptDetail(record) {
      console.log(record)
      this.$router.push({name: 'studentList', query: {jwDeptId: record.id, gradeList: this.queryForm.gradeList, feeDategoryList: this.queryForm.feeDategoryList}, params: {jwDeptId: record.id, gradeList: this.queryForm.gradeList, feeDategoryList: this.queryForm.feeDategoryList}})
    }
  }
}
</script>
