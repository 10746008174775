/**
 * 财务接口
 * 财务接口API
 *
 * OpenAPI spec version: 0.2.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import ApiClient from '../../ApiClient';
import ChargeQueryForm from '../../bill/model/ChargeQueryForm';
import DeptQueryForm from '../../bill/model/DeptQueryForm';
import FeeStatVo from '../../bill/model/FeeStatVo';
import PagingStudentStatVo from '../../bill/model/PagingStudentStatVo';
import StudentQueryForm from '../../bill/model/StudentQueryForm';
  /**
   * Fee service.
   * @module bill/api/FeeApi
   * @version 0.2.0
   */

  /**
   * Constructs a new FeeApi. 
   * @alias module:bill/api/FeeApi
   * @class
   * @param {module:ApiClient} apiClient Optional API client implementation to use,
   * default to {@link module:ApiClient#instance} if unspecified.
   */
  var exports = function(apiClient) {
    this.apiClient = apiClient || ApiClient.instance;



    /**
     * 班主任统计
     * @param {Integer} pageNum 页码
     * @param {module:bill/model/ChargeQueryForm} body 请求参数body
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:bill/model/PagingStudentStatVo}
     */
this.chargeTeacherStat = function(pageNum, body) {
      var postBody = body;

      // verify the required parameter 'pageNum' is set
      if (pageNum == undefined || pageNum == null) {
        throw "Missing the required parameter 'pageNum' when calling chargeTeacherStat";
      }

      // verify the required parameter 'body' is set
      if (body == undefined || body == null) {
        throw "Missing the required parameter 'body' when calling chargeTeacherStat";
      }


      var pathParams = {
      };
      var queryParams = {
        'pageNum': pageNum
      };
      var headerParams = {
      };
      var formParams = {
      };

      var isPublic = false;

      var authNames = [];
      var contentTypes = [];
      var accepts = [];
      var returnType = PagingStudentStatVo;
      return this.apiClient.callApi(
        '/api/fee/charge_teacher_stat', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType,isPublic
      );
    }


    /**
     * 分院统计
     * @param {module:bill/model/DeptQueryForm} body 请求参数body
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:bill/model/FeeStatVo>}
     */
this.deptStat = function(body) {
      var postBody = body;

      // verify the required parameter 'body' is set
      if (body == undefined || body == null) {
        throw "Missing the required parameter 'body' when calling deptStat";
      }


      var pathParams = {
      };
      var queryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var isPublic = false;

      var authNames = [];
      var contentTypes = [];
      var accepts = [];
      var returnType = [FeeStatVo];
      return this.apiClient.callApi(
        '/api/fee/dept_stat', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType,isPublic
      );
    }


    /**
     * 分院统计
     * @param {module:bill/model/DeptQueryForm} body 请求参数body
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:bill/model/FeeStatVo>}
     */
this.deptStatDetail = function(body) {
      var postBody = body;

      // verify the required parameter 'body' is set
      if (body == undefined || body == null) {
        throw "Missing the required parameter 'body' when calling deptStatDetail";
      }


      var pathParams = {
      };
      var queryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var isPublic = false;

      var authNames = [];
      var contentTypes = [];
      var accepts = [];
      var returnType = [FeeStatVo];
      return this.apiClient.callApi(
        '/api/fee/dept_stat_detail', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType,isPublic
      );
    }


    /**
     * 学生统计
     * @param {Integer} pageNum 页码
     * @param {module:bill/model/StudentQueryForm} body 请求参数body
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:bill/model/PagingStudentStatVo}
     */
this.studentStat = function(pageNum, body) {
      var postBody = body;

      // verify the required parameter 'pageNum' is set
      if (pageNum == undefined || pageNum == null) {
        throw "Missing the required parameter 'pageNum' when calling studentStat";
      }

      // verify the required parameter 'body' is set
      if (body == undefined || body == null) {
        throw "Missing the required parameter 'body' when calling studentStat";
      }


      var pathParams = {
      };
      var queryParams = {
        'pageNum': pageNum
      };
      var headerParams = {
      };
      var formParams = {
      };

      var isPublic = false;

      var authNames = [];
      var contentTypes = [];
      var accepts = [];
      var returnType = PagingStudentStatVo;
      return this.apiClient.callApi(
        '/api/fee/student_stat', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType,isPublic
      );
    }
  };

  export default exports;
