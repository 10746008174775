import * as dd from 'dingtalk-jsapi'
export default {
  getItem: function(key) {
    return new Promise((resolve,reject) => {
      dd.util.domainStorage.getItem({
        name: key , // 存储信息的key值
        onSuccess : function(info) {
          console.log(info)
          resolve(info.value)
        },
        onFail : function(err) {
          resolve()
        }
      });
    })
  }
}